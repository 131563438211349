import { strToNumber } from 'common/utils/numbers';
import _get from 'lodash/get';

export const getInventorySparePartGroup = item => {
  const group = _get(item, 'vessel_systems[0]');

  if (group) {
    return {
      id: `${group?.id}${group?.parent ? `_${group?.parent?.id}` : ''}`,
      name: `${group?.parent?.description ? `${group?.parent?.description} - ` : ''}${
        group?.description
      }`
    };
  }

  return null;
};

export const getEntityGroup = entity =>
  entity.item_type === 'store'
    ? entity?.item?.store_group
    : getInventorySparePartGroup(entity?.item);

export const parseInventoryData = (response, { isOnBoard }) => {
  const results = [];
  const isPaginated = !!response?.meta;

  const entities = isPaginated ? response?.data : response;

  entities.forEach(entity => {
    const vessel = { id: entity.vessel_id, name: entity.vessel_name };
    const group = getEntityGroup(entity);

    if (!isOnBoard && !results.find(data => data.isVessel && data.vessel?.id === vessel.id)) {
      results.push({ vessel, isVessel: true });
    }

    if (
      group &&
      !results.find(
        data => data.isGroup && data.group?.id === group.id && data.vessel_id === vessel.id
      )
    ) {
      results.push({
        group,
        isGroup: true,
        vessel_id: vessel?.id,
        full_group_name: entity?.full_group_name
      });
    }

    results.push(entity);
  });

  if (isPaginated) {
    return { ...response, data: results };
  }

  return { data: results, meta: { total: response?.length } };
};

export const parseNewSurveyItems = data => {
  return data
    .filter(item => item.quantity || item.quantity === 0)
    .map(item => {
      return {
        entity_id: item.id,
        entity_type: 'store',
        quantity: item.quantity
      };
    });
};

export const parseNewSurveySpareParts = data => {
  return data
    .filter(item => item.quantity || item.quantity === 0)
    .map(item => {
      return {
        entity_id: item.id,
        entity_type: 'spare_part',
        quantity: item.quantity,
        quantity_new: strToNumber(item?.quantity_new),
        quantity_used: strToNumber(item?.quantity_used),
        quantity_in_repair: strToNumber(item?.quantity_in_repair),
        location: item?.location || null
      };
    });
};
