import { RootState } from '@/store';
import { createSelector } from 'reselect';
import { isHighlightedSupplierField, supplierItemsHaveValue, itemsHaveValue } from './helpers-ts';
import { ItemConfigurationKey } from '@/common/components/purchasing/requisition/categories/items/config';
import {
  LastDeliverdItemData,
  LastDeliverdItemTotal,
  PurchasingCategory,
  PurchasingRequisitionOnBoardStatus,
  PurchasingRequisitionStatusBase,
  PurchasingUnit,
  RequisitionItem,
  Supplier
} from '@/common/types/purchasing';
import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _some from 'lodash/some';
import { selectListDefaultOptions } from '@/store/lists/selectors-ts';
import { Vessel } from '@/common/types/vessel';
import { DEFAULT_ITEMS_TABLE_COMPARISON_BENCHMARK_THRESH } from './reducer';
import { strToNumber } from '@/ts-common/utils/numbers';

/* Types */
type Key = string | number | unknown[];

type SupplierKey = string | number;

type ItemKey = string;

type RequisitionItemKey = string;

export type ItemSupplierDetailsType = {
  [fieldKey: string]: unknown;
};

export type SupplierRequisitionIdType = number;

export type SupplierDetailsType = Record<SupplierKey, ItemSupplierDetailsType | undefined>;
export type SuppliersWithEquivalentCurrencyType = SupplierRequisitionIdType[];

export interface ItemType extends RequisitionItem {
  supplier_details: SupplierDetailsType;
  is_out_of_the_list?: boolean;
  store_group: {
    id: number;
    is_extra_charges: boolean;
  } | null;
}

export type SupplierRequisitionItemTotal = {
  item_id: number;
  budgeted_amount?: number;
  delivered?: number;
  remaining?: number;
  purchasing_unit?: PurchasingUnit;
};

export type CategoryDataType = {
  [categoryID: number]: PurchasingCategory;
};

export type CategoryItemType = {
  [categoryID: number]: string[];
};

type NormalizedItemsState = { [fieldKey: ItemKey]: ItemType | null };

type BenchmarkThreshState = number;

/* -- */

/* Base */
const selectStateReducer = (state: RootState) => state.purchasing.requisitions;
const selectIsOnboard = (state: RootState) => state.isOnBoard;
const getKey = (_: RootState, key: Key) => key;
const getSecondaryKey = (_: RootState, __: unknown, key: Key) => key;
const getThirdKey = (_: RootState, __: unknown, ___: unknown, key: Key) => key;
const getFourthKey = (_: RootState, __: unknown, ___: unknown, ____: unknown, key: Key) => key;
// const getItemKey = (_: RootState, key: ItemKey) => key;
const getId = (_: RootState, key: number) => key;
const getItemType = (_: RootState, __: number, type: string) => type;

// const selectIsOnboard = (state: RootState): boolean => state.isOnBoard;

const getCategoryIdAsThirdKey = (_: RootState, __: unknown, ___: unknown, key: number): number =>
  key;

/* Basic */
export const selectRequisitionVessel = (state: RootState): Vessel | Vessel[] | null =>
  selectStateReducer(state).requisitionVessel;

export const selectRequisitionIsBulk = createSelector(
  selectRequisitionVessel,
  selected => Array.isArray(selected) && selected.length > 1
);

export const selectRequisitionBulkVessels = createSelector(
  selectRequisitionVessel,
  selectRequisitionIsBulk,
  (selected, isBulk) => (isBulk ? selected : []) as Vessel[]
);

export const selectActiveRequisition = (state: RootState) => selectStateReducer(state).active;

export const selectActiveRequisitionID = (state: RootState) => selectActiveRequisition(state)?.id;

export const selectOnBoardStatus = (state: RootState): PurchasingRequisitionOnBoardStatus =>
  selectStateReducer(state).onBoardStatus;

export const selectRequisitionStatus = (state: RootState): PurchasingRequisitionStatusBase | null =>
  selectActiveRequisition(state)?.status;

export const selectRequisitionStatusLabel = (state: RootState) =>
  selectRequisitionStatus(state)?.label;

export const selectRequistionLastUpdatedAt = (state: RootState): number | null =>
  selectStateReducer(state).lastUpdatedAt;

/* -- */

/* Comments */
const selectItemCommentsDrawer = (state: RootState) => selectStateReducer(state).itemCommentsDrawer;

export const selectIsItemCommentsDrawerOpen = (state: RootState) =>
  selectItemCommentsDrawer(state).isOpen;

export const selectItemCommentsDrawerItemId = (state: RootState) =>
  selectItemCommentsDrawer(state).itemID;

export const selectItemCommentsDrawerActualItemID = (state: RootState) =>
  selectItemCommentsDrawer(state).requisitionActualItemID;

export const selectItemCommentsDrawerRequisitionItemId = (state: RootState) =>
  selectItemCommentsDrawer(state).requisitionItemID;
/* -- */

/* Category Drawer */
const selectCategoryDrawer = (state: RootState) => selectStateReducer(state).categoryDrawer;

export const selectCategoryDrawerIsOpen = (state: RootState) => selectCategoryDrawer(state).isOpen;

export const selectCategoryDrawerCategoryId = (state: RootState) =>
  selectCategoryDrawer(state).categoryID;
/* -- */

/* Po Details */
export const selectIsPoDetailsDrawerOpen = (state: RootState) =>
  selectStateReducer(state).isSupplierPoDetailsDrawerOpen;
/* -- */

/* Items */
const selectPurchasingRequisitionItems = (state: RootState): NormalizedItemsState =>
  selectStateReducer(state).items;

export const selectPurchasingRequisitionUnlistedItems = (state: RootState): ItemKey[] =>
  selectStateReducer(state).unlistedItems;

export const selectItemToBeReplaced = (state: RootState) =>
  selectStateReducer(state).selectedItemToBeReplaced;

export const selectItem = (state: RootState, itemId: ItemKey) =>
  selectPurchasingRequisitionItems(state)[itemId];

export const isItemOutOfTheList = createSelector(
  selectItem,
  item => item?.is_out_of_the_list === true
);

export const selectItemBasicFields = (state: RootState, itemId: ItemKey): RequisitionItem => {
  const { supplier_details, ...rest } = selectItem(state, itemId);
  return rest;
};

export const selectItemBasicField = (
  state: RootState,
  itemId: ItemKey,
  fieldKey: RequisitionItemKey
) => selectItemBasicFields(state, itemId)[fieldKey];

export const selectIsItemInitial = (state: RootState, itemId: ItemKey) =>
  selectItem(state, itemId)?.is_initial;

export const selectStoreItemIsExtraCharge = createSelector(
  selectItem,
  item => item?.item_type === 'store' && item.store_group?.is_extra_charges
);

export const selectItemIsNewlyAdded = createSelector(
  selectIsItemInitial,
  selectRequisitionStatusLabel,
  (isInitial, status): boolean => (status === 'rqn' || status === 'rfq') && !isInitial
);

export const itemRevisedQuantityQuantityIsZero = createSelector(selectItem, item => {
  const qty = _get(item, `revised_quantity`, null);

  return +qty === 0;
});

/* -- */

/* Last Delivered */
const selectLastDeliveredItems = (state: RootState) =>
  selectStateReducer(state).lastDeliveredItems as LastDeliverdItemData[];

const selectLastDeliveredTotals = (state: RootState): LastDeliverdItemTotal[] =>
  selectStateReducer(state).lastDeliveredTotals;

export const selectItemLastDeliveredData = createSelector(
  selectLastDeliveredItems,
  getId,
  getItemType,
  (lastDeliveredItems, id: number, itemType: string) =>
    lastDeliveredItems.filter(data => data.item_id === id && data.item_type === itemType)
);

export const selectItemLastDeliveredTotals = createSelector(
  selectLastDeliveredTotals,
  getId,
  getItemType,
  (totals, id: number, itemType: string) =>
    totals.find(
      (total: LastDeliverdItemTotal) => total.item_id === id && total.item_type === itemType
    )
);
/* -- */

/* Suppliers */
export const selectVisibleSuppliers = (state: RootState): number[] =>
  selectStateReducer(state).visibleSuppliers;

export const selectSuppliersData = (state: RootState) =>
  selectStateReducer(state).suppliersData as Record<SupplierKey, Supplier | undefined>;

export const isTheSameSupplierUsedMultipleTimes = (state: RootState, id: number) => {
  const suppliers = Object.values(selectSuppliersData(state)).filter(
    selected => selected.supplier_id === id
  );
  return suppliers.length >= 2;
};

export const selectSuppliers = (state: RootState) => selectStateReducer(state).suppliers;

export const selectSuppliersWithEquivalentCurrency = (state: RootState) =>
  selectStateReducer(state).suppliersWithEquivalentCurrency as SuppliersWithEquivalentCurrencyType;

export const selectSupplierHasEquivalentCurrency = createSelector(
  selectSuppliersWithEquivalentCurrency,
  getKey,
  (suppliersWithEquivalentCurrency, supplierRequisitionID) => {
    return suppliersWithEquivalentCurrency.includes(+supplierRequisitionID);
  }
);

export const selectSupplier = (state: RootState, key: SupplierKey) => {
  if (!key) return;

  return selectSuppliersData(state)[key];
};

export const selectItemSupplierDetails = createSelector(selectItem, item => {
  const { supplier_details } = item;
  return supplier_details as ItemSupplierDetailsType;
});

export const selectItemSupplierDetailsField = createSelector(
  selectItemSupplierDetails,
  getSecondaryKey,
  getThirdKey,
  (supplierDetails, fieldKey, supplierRequisitionID) =>
    _get(supplierDetails, `[${supplierRequisitionID}][${fieldKey}]`)
);

export const supplierAvailableQuantityIsZero = createSelector(
  selectItemSupplierDetails,
  getSecondaryKey,
  (supplierDetails, supplierRequisitionID) => {
    const qty = _get(supplierDetails, `[${supplierRequisitionID}].available_quantity`);

    return +qty === 0;
  }
);

export const selectItemSuppliers = createSelector(
  selectSuppliersData,
  selectSuppliers,
  (suppliersData, suppliers) =>
    _mapValues(
      _pickBy(suppliersData, supplier => supplier.status.label !== 'rqn'),
      supplier => ({
        ...supplier,
        supplier_sort_index: suppliers.findIndex(
          (supplierRequisitionID: number) => supplierRequisitionID === supplier.id
        )
      })
    )
);

export const suppliersHaveEmptyField = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  selectItemSuppliers,
  (items, fieldKey, itemSuppliers) => {
    const filledItems = Object.keys(
      _pickBy(items, item =>
        Object.keys(item.supplier_details)
          .filter(supplierRequisitionID => itemSuppliers[supplierRequisitionID])
          .every(
            supplierRequisitionID =>
              item.supplier_details[supplierRequisitionID][fieldKey] ||
              item.supplier_details[supplierRequisitionID][fieldKey] === 0
          )
      )
    );
    return !filledItems.length || filledItems.length !== Object.keys(items).length;
  }
);

export const supplierHasValidApprovedQuantities = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  (items, supplierRequisitionID) =>
    _some(items, item => item.supplier_details[supplierRequisitionID]?.approved_quantity > 0)
);

export const findTheFirstSupplierWithApprovedQuantity = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  (items, suppliers: Supplier[] | undefined) => {
    if (Array.isArray(suppliers)) {
      return suppliers.find(supplier =>
        _some(items, item => item.supplier_details[supplier.id]?.approved_quantity > 0)
      );
    } else {
      return null;
    }
  }
);

export const selectIsMaxSupplierField = createSelector(
  selectItemSupplierDetails,
  getSecondaryKey,
  getThirdKey,
  selectSuppliersWithEquivalentCurrency,
  getFourthKey,
  selectVisibleSuppliers,
  (
    supplierDetails,
    fieldKey,
    supplierRequisitionID,
    suppliersWithEquivalentCurrency,
    comparisonFieldKey,
    visibleSuppliers
  ) => {
    return isHighlightedSupplierField(
      {
        visibleSuppliers,
        supplierDetails,
        supplierRequisitionID,
        suppliersWithEquivalentCurrency,
        fieldKey,
        comparisonFieldKey
      },
      'max'
    );
  }
);

export const selectIsMinSupplierField = createSelector(
  selectItemSupplierDetails,
  getSecondaryKey,
  getThirdKey,
  selectSuppliersWithEquivalentCurrency,
  getFourthKey,
  selectVisibleSuppliers,
  (
    supplierDetails,
    fieldKey,
    supplierRequisitionID,
    suppliersWithEquivalentCurrency,
    comparisonFieldKey,
    visibleSuppliers
  ) => {
    return isHighlightedSupplierField(
      {
        visibleSuppliers,
        supplierDetails,
        supplierRequisitionID,
        suppliersWithEquivalentCurrency,
        fieldKey,
        comparisonFieldKey
      },
      'min'
    );
  }
);

export const selectIsSupplierForwardingCaseEnabled = (state: RootState, key: Key) =>
  selectSupplier(state, key)?.is_forwarding_enabled;

export const selectSupplierStatus = (state: RootState, key: Key): PurchasingRequisitionStatusBase =>
  selectSupplier(state, key)?.status;

/* -- */

/* Table Configuration */
export const selectActiveTableConfiguration = (state: RootState) =>
  selectStateReducer(state).activeTableConfiguration;

export const selectActiveTableConfigurationSettings = createSelector(
  selectActiveTableConfiguration,
  config => config.settings
);

export const selectItemsTableConfiguration = (state: RootState): ItemConfigurationKey[] =>
  selectStateReducer(state).itemsTableConfiguration;

export const selectItemsTableComparisonViewEnabled = (state: RootState): boolean =>
  selectStateReducer(state).itemsTableComparisonViewEnabled;

export const selectItemsTableComparisonCommentsVisibility = (state: RootState): boolean =>
  selectStateReducer(state).itemsTableComparisonVisibleComments;

export const selectItemsTableComparisonLocalCurrenciesVisibility = (state: RootState): boolean =>
  selectStateReducer(state).itemsTableComparisonVisibleLocalCurrencies;

export const selectItemsTableComparisonBenchmarkThresh = (state: RootState) => {
  const benchmarkThresh = selectStateReducer(state)
    .itemsTableComparisonBenchmarkThresh as BenchmarkThreshState;

  return benchmarkThresh;
};

export const selectItemsTableComparisonBenchmarkThreshIsApplied = (state: RootState) => {
  const benchmarkThresh = selectItemsTableComparisonBenchmarkThresh(state);

  return benchmarkThresh !== DEFAULT_ITEMS_TABLE_COMPARISON_BENCHMARK_THRESH;
};

export const selectCanCompareSupplierQuotation = createSelector(
  selectSuppliersData,
  (state: RootState) => selectListDefaultOptions(state, 'purchasing-requisition-statuses'),
  (suppliersData, statuses) => {
    const minStatus = statuses?.find((st: PurchasingRequisitionStatusBase) => st.label === 'pr');

    return Object.keys(suppliersData).some(
      supplierRequisitionID =>
        !suppliersData[supplierRequisitionID]?.rejected &&
        suppliersData[supplierRequisitionID]?.status.sort_index >= minStatus?.sort_index
    );
  }
);

/* -- */

/* Categories */

export const selectCategories = (state: RootState): number[] =>
  selectStateReducer(state).categories;

export const selectAllCategoryItems = (state: RootState) =>
  selectStateReducer(state).categoryItems as CategoryItemType;

export const selectCategoryData = (state: RootState) =>
  selectStateReducer(state).categoryData as CategoryDataType;

export const selectCategory = (state: RootState, categoryID: number) =>
  selectCategoryData(state)[categoryID];

export const selectIsPMSCategory = (state: RootState, categoryID: number) =>
  selectCategory(state, categoryID).item_types === 'spare_part';

export const selectCategoryHasExtraCharges = (state: RootState, categoryID: number) =>
  selectCategory(state, categoryID).has_extra_charges;

export const selectCategoryItems = (state: RootState, categoryID: number): string[] | undefined =>
  selectAllCategoryItems(state)[categoryID];

export const selectNumberOfCategoryItems = createSelector(
  selectCategoryItems,
  categoryItems => categoryItems?.length
);

const getCategoryItemForSupplierItemFields = createSelector(
  selectAllCategoryItems,
  getCategoryIdAsThirdKey,
  (allCategoryItems, categoryID?: number) => {
    if (categoryID) return allCategoryItems[categoryID];

    return null;
  }
);

export const selectCategoryItemIds = createSelector(
  selectPurchasingRequisitionItems,
  selectCategoryItems,
  (allItems, categoryItems = []) => {
    return categoryItems.map(key => allItems[key]?.id);
  }
);

export const allSupplierItemFieldsHaveValue = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  getSecondaryKey,
  getCategoryItemForSupplierItemFields,
  (items, fieldKey, supplierRequisitionID, categoryItems) => {
    return supplierItemsHaveValue(
      { items, fieldKey, supplierRequisitionID, categoryItems },
      'and',
      false
    );
  }
);

export const allItemFieldsHaveValue = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  (items, fieldKey) => {
    return itemsHaveValue({ items, fieldKey }, 'and', false);
  }
);

export const someSupplierItemFieldsHaveValue = createSelector(
  selectPurchasingRequisitionItems,
  getKey,
  getSecondaryKey,
  getCategoryItemForSupplierItemFields,
  (items, fieldKey, supplierRequisitionID, categoryItems) => {
    return supplierItemsHaveValue(
      { items, fieldKey, supplierRequisitionID, categoryItems },
      'or',
      false
    );
  }
);

export const selectColumnTotals = createSelector(
  selectCategoryItems,
  getSecondaryKey,
  getThirdKey,
  getFourthKey,
  selectPurchasingRequisitionItems,
  (categoryItems, fieldKey, supplierRequisitionID, isPms, items) =>
    _reduce(
      categoryItems,
      (result, itemID) => {
        const item = items[itemID];

        if ((isPms && itemID.startsWith('store')) || (!isPms && itemID.startsWith('spare_part'))) {
          //We need this so the totals will be different for each box, we need spare_part and store totals seperately
          return result + 0;
        }

        const fieldValue = supplierRequisitionID
          ? _get(item, `supplier_details[${supplierRequisitionID}][${fieldKey}]`)
          : item?.[fieldKey];

        const parsedValue = strToNumber(fieldValue) || 0;

        return result + parsedValue;
      },
      0
    )
);
/* -- */

/* Lock for office/vessel */

export const selectActiveRequisitionIsCreatedOnVessel = createSelector(
  selectActiveRequisition,
  active => active?.is_created_on_vessel
);

export const selectVesselRequisitionIsLockedForOffice = createSelector(
  selectOnBoardStatus,
  selectActiveRequisitionIsCreatedOnVessel,
  selectIsOnboard,
  (onBoardStatus, isCreatedOnVessel, isOnBoard) =>
    isCreatedOnVessel && !isOnBoard && onBoardStatus !== 'submitted' ? true : false
);

export const selectCanEditRequisition = createSelector(
  selectOnBoardStatus,
  selectIsOnboard,
  selectActiveRequisition,
  selectVesselRequisitionIsLockedForOffice,
  (onBoardStatus, isOnBoard, activeRequisition, lockedForOffice) => {
    if (lockedForOffice) return false;

    return isOnBoard
      ? onBoardStatus !== 'submitted'
      : (activeRequisition && onBoardStatus === 'draft') || !activeRequisition;
  }
);

export const selectIsEligibleForPendingReview = (state: RootState): boolean =>
  selectStateReducer(state).isEligibleForPendingReview;

export const selectSupplierPurchaseOrderIsConfirmed = (state: RootState, key: Key) =>
  selectSupplier(state, key)?.purchase_order_confirmed as boolean | undefined;

export const selectSupplierPurchaseOrderIsCanceledBySupplier = (state: RootState, key: Key) =>
  selectSupplier(state, key)?.purchase_order_canceled_by_supplier as boolean | undefined;

export const selectSupplierPurchaseOrderIsCanceledByCompany = (state: RootState, key: Key) =>
  selectSupplier(state, key)?.purchase_order_canceled_by_company as boolean | undefined;

/* Purchasing emails */

export const selectShouldIncludeDefaultEmailAttachments = (state: RootState): boolean =>
  selectStateReducer(state).shouldIncludeDefaultEmailAttachments;
