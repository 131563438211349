const permissions = {
  office: {
    fleet: {
      view: 'office.fleet.view',
      view_vessel_particulars: 'office.fleet.view_vessel_particulars',
      view_crew: 'office.fleet.view_crew',
      view_superintendents: 'office.fleet.view_superintendents',
      view_itinerary: 'office.fleet.view_itinerary',
      view_charter_party: 'office.fleet.view_charter_party',
      view_performance: 'office.fleet.view_performance'
    },
    events: {
      list: {
        view: 'office.events.list'
      },
      calendar: {
        view: 'office.events.calendar'
      },
      reports: {
        view: 'office.events.reports'
      }
    },
    dashboard: {
      view: 'office.dashboard'
    },
    jobs: {
      view: 'office.jobs.view',
      create: 'office.jobs.create',
      edit: 'office.jobs.update',
      list: {
        view: 'office.jobs.list',
        regular: {
          view: 'office.jobs.list.regular'
        },
        flag_extension_and_dispensation: {
          view: 'office.jobs.list.flag_extension_and_dispensation'
        },
        orca_jobs: {
          view: 'office.jobs.list.orca_jobs'
        },
        findings: {
          view: 'office.jobs.list.findings'
        }
      }
    },
    projects: {
      list: 'office.projects.list',
      view: 'office.projects.view',
      create: 'office.projects.create',
      edit: 'office.projects.update'
    },
    contacts: {
      companies: {
        view: 'office.contacts.companies.view',
        create: 'office.contacts.companies.create',
        archive: 'office.contacts.companies.archive_company',
        profile: {
          view: 'office.contacts.companies.profile.view',
          edit: 'office.contacts.companies.profile.edit_main_info',
          evaluations: {
            view: 'office.contacts.companies.profile.evaluations.view'
          },
          connected_companies: {
            view: 'office.contacts.companies.profile.connected_companies.view',
            edit: 'office.contacts.companies.profile.connected_companies.edit'
          },
          bank_accounts: {
            view: 'office.contacts.companies.profile.bank_accounts.view',
            edit: 'office.contacts.companies.profile.bank_accounts.edit'
          },
          accounting: {
            view: 'office.contacts.companies.profile.accounting.view',
            edit: 'office.contacts.companies.profile.accounting.edit'
          }
        }
      },
      persons: {
        view: 'office.contacts.persons.view',
        create: 'office.contacts.persons.create',
        delete: 'office.contacts.persons.delete',
        archive: 'office.contacts.persons.archive',
        profile: {
          view: 'office.contacts.persons.profile.view',
          edit: 'office.contacts.persons.profile.edit',
          documents: {
            view: 'office.contacts.persons.profile.documents.view',
            edit: 'office.contacts.persons.profile.documents.edit'
          },
          esign: {
            view: 'office.contacts.persons.profile.esign.view',
            edit: 'office.contacts.persons.profile.esign.edit'
          }
        }
      },
      application_access: {
        grant: 'office.contacts.grant_application_access'
      },
      security: {
        all_users: {
          view: 'office.contacts.security_all_users',
          edit_username: 'office.contacts.security_all_users.edit_username',
          edit_password: 'office.contacts.security_all_users.edit_password',
          change_two_factor_authentication:
            'office.contacts.security_all_users.add_change_two_factor_authentication',
          remove_two_factor_authentication:
            'office.contacts.security_all_users.remove_two_factor_authentication',
          deactivate_user: 'office.contacts.security_all_users.deactivate_user',
          set_access_to_vessels: 'office.contacts.security_all_users.edit_vessel_access'
        },
        own_profile: {
          view: 'office.contacts.security_own_profile',
          edit_username: 'office.contacts.security_own_profile.edit_username',
          edit_password: 'office.contacts.security_own_profile.edit_password',
          change_two_factor_authentication:
            'office.contacts.security_own_profile.add_change_two_factor_authentication'
        }
      }
    },
    vessels: {
      list: 'office.vessels.list',
      create: 'office.vessels.create',
      edit: 'office.vessels.edit',
      delete: 'office.vessels.list',
      deactivate: 'office.vessels.deactivate',
      archive: 'office.vessels.archive',
      view: 'office.vessels.view',
      document_manager: {
        view: 'office.vessels.document_manager'
      },
      documents: {
        view: 'office.vessels.view_vessel_documents',
        edit: 'office.vessels.edit_vessel_documents'
      },
      compare: 'office.vessels.compare'
    },
    voyages: {
      itinerary: {
        view: 'office.voyages.itinerary.view',
        edit: 'office.voyages.itinerary.add_edit_delete'
      },
      charter_parties: {
        view: 'office.voyages.charter_parties.view',
        edit: 'office.voyages.charter_parties.edit'
      },
      captain_reports: {
        list: { view: 'office.voyages.captain_reports.list' },
        view: 'office.voyages.captain_reports.view',
        create: 'office.voyages.captain_reports.create_from_office',
        edit: 'office.voyages.captain_reports.update',
        delete: 'office.voyages.captain_reports.delete',
        approve: 'office.voyages.captain_reports.approve',
        revoke_approval: 'office.voyages.captain_reports.update_approved',
        send_for_correction: 'office.voyages.captain_reports.send_for_correction',
        review_by_technical: 'office.voyages.captain_reports.review_by_technical'
      },
      estimations: {
        list: {
          view: 'office.voyages.estimations.list'
        },
        view: 'office.voyages.estimations.view',
        create: 'office.voyages.estimations.create',
        edit: 'office.voyages.estimations.create',
        delete: 'office.voyages.estimations.create',
        comparison: 'office.voyages.estimations.list'
      },
      market_indexes: {
        view: 'office.voyages.market_indexes'
      },
      ports: {
        view: 'office.voyages.ports'
      },
      bunker_prices: {
        view: 'office.voyages.bunker_prices'
      },
      reports: {
        view: 'office.voyages.reports'
      },
      da: {
        view: 'office.voyages.da',
        view_case: 'office.voyages.da.view',
        update_done_cases: 'office.voyages.da.done_case_update',
        edit: 'office.voyages.da.edit'
      },
      freight_hire: {
        view: 'office.voyages.freight_hire_collection_invoices.view',
        edit: 'office.voyages.freight_hire_collection_invoices.edit',
        create: 'office.voyages.freight_hire_collection_invoices.edit',
        delete: 'office.voyages.freight_hire_collection_invoices.edit'
      }
    },
    performance: {
      reports: {
        view: 'office.performance.reports'
      },
      environmental: {
        view: 'office.performance.environmental'
      },
      setups: {
        view: 'office.performance.setups'
      }
    },
    forms: {
      view: 'office.forms.view',
      create: 'office.forms.create',
      delete: 'office.forms.delete',
      edit_status: 'office.forms.edit_status',
      edit_values: 'office.forms.edit_values',
      chat: {
        view: 'office.forms.chat.view',
        create: 'office.forms.chat.create'
      },
      planning: {
        view: 'office.forms.planning.view'
      },
      obligations: {
        view: 'office.forms.obligations.view',
        create: 'office.forms.obligations.create',
        delete: 'office.forms.obligations.delete'
      },
      list: {
        view: 'office.forms.list.view'
      },
      reports: {
        view: 'office.forms.reports'
      }
    },
    manuals: {
      list: {
        view: 'office.manuals'
      },
      versioning: {
        view: 'office.manuals.versioning.view'
      },
      view: 'office.manuals.view',
      create: 'office.manuals.create',
      approve_change_request: 'office.manuals.approve_change_request',
      release_new_version: 'office.manuals.versioning.release_new_version',
      ai_search: 'office.manuals.ai_ask'
    },
    files: {
      view: 'office.files.view',
      edit: 'office.files.edit',
      delete: 'office.files.delete'
    },
    crew: {
      comparison: 'office.crew.seamen_list',
      create: 'office.crew.seamen_create',
      archive: 'office.crew.seamen_archive',
      edit: 'office.crew.seamen_edit',
      contracts: {
        view: 'office.crew.contracts'
      },
      seaman_profile: {
        view: 'office.crew.seaman_profile',
        edit_status: 'office.crew.seaman_profile.status',
        personal: {
          view: 'office.crew.seaman_profile.personal',
          main_info: {
            view: 'office.crew.seaman_profile.personal.main_info.view',
            edit: 'office.crew.seaman_profile.personal.main_info.edit'
          },
          marital_status: {
            view: 'office.crew.seaman_profile.personal.marital_status.view',
            edit: 'office.crew.seaman_profile.personal.marital_status.edit'
          },
          languages: {
            view: 'office.crew.seaman_profile.personal.languages.view',
            edit: 'office.crew.seaman_profile.personal.languages.edit'
          },
          notes: {
            view: 'office.crew.seaman_profile.personal.notes.view',
            create: 'office.crew.seaman_profile.personal.notes.create',
            edit: 'office.crew.seaman_profile.personal.notes.edit',
            delete: 'office.crew.seaman_profile.personal.notes.delete'
          },
          security: 'office.crew.seaman_profile.personal.security',
          communication_info: {
            view: 'office.crew.seaman_profile.personal.communication_info.view',
            edit: 'office.crew.seaman_profile.personal.communication_info.edit'
          }
        },
        contracts_detailed: {
          view: 'office.crew.seaman_profile.contracts.view',
          edit: 'office.crew.seaman_profile.contracts.edit',
          delete: 'office.crew.seaman_profile.contracts.delete',
          create: 'office.crew.seaman_profile.contracts.create',
          create_previous: 'office.crew.seaman_profile.contracts.previous_contracts_create',
          edit_previous: 'office.crew.seaman_profile.contracts.previous_contracts_edit',
          delete_previous: 'office.crew.seaman_profile.contracts.previous_contracts_delete'
        },
        contracts_list: {
          view: 'office.crew.seaman_profile.contracts_list'
        },
        evaluations: {
          view: 'office.crew.seaman_profile.evaluations.view',
          create: 'office.crew.seaman_profile.evaluations.create',
          recommend_for_promotion: 'office.crew.seaman_profile.evaluations.recommend_for_promotion',
          recommend_for_reemployment:
            'office.crew.seaman_profile.evaluations.recommend_for_reemployment',
          send_for_consent: 'office.crew.seaman_profile.evaluations.send_for_consent',
          submit: 'office.crew.seaman_profile.evaluations.submit'
        },
        skills_experience: {
          view: 'office.crew.seaman_profile.skills_experience'
        },
        watchkeeping: {
          view: 'office.crew.seaman_profile.watchkeeping'
        },
        payroll: {
          view: 'office.crew.seaman_profile.payroll'
        },
        training: {
          view: 'office.crew.seaman_profile.training.view',
          edit: 'office.crew.seaman_profile.training.edit'
        },
        medical: {
          view: 'office.crew.seaman_profile.medical'
        },
        documents: {
          view: 'office.crew.seaman_profile.documents.view',
          edit: 'office.crew.seaman_profile.documents.edit'
        },
        policies: {
          view: 'office.crew.seaman_profile.policies'
        },
        plan_promotion: {
          view: 'office.crew.seaman_profile.plan_promotion'
        }
      },
      seamen_list: {
        view: 'office.crew.seamen_list'
      },
      crew_list: {
        view: 'office.crew.crew_list'
      },
      crew_planning: {
        view: 'office.crew.crew_planning'
      },
      ticketing: {
        view: 'office.crew.ticketing'
      },
      document_manager: {
        view: 'office.crew.document_manager'
      },
      evaluation_obligations: {
        view: 'office.crew.evaluation_obligations'
      },
      watchkeeping: {
        view: 'office.crew.watchkeeping'
      },
      reports: {
        view: 'office.crew.reports'
      }
    },
    pms: {
      jobs: {
        view: 'office.pms.jobs'
      },
      library: {
        view: 'office.pms.library.view_lists',
        systems: {
          view: 'office.pms.library.systems.view',
          create: 'office.pms.library.systems.create',
          edit: 'office.pms.library.systems.edit',
          delete: 'office.pms.library.systems.delete',
          edit_spare_parts: 'office.pms.library.systems.edit_spare_parts',
          delete_spare_parts: 'office.pms.library.systems.delete_spare_parts',
          edit_jobs: 'office.pms.library.systems.edit_jobs',
          delete_jobs: 'office.pms.library.systems.delete_jobs'
        },
        edit_groups: 'office.pms.library.edit_groups',
        system_assignments: {
          create: 'office.pms.library.system_assignments.create',
          delete: 'office.pms.library.system_assignments.delete'
        }
      },
      running_hours: {
        view: 'office.pms.running_hours'
      },
      reports: {
        view: 'office.pms.reports'
      },
      spare_parts_inventory: {
        view: 'office.pms.spare_parts_inventory'
      },
      configuration: {
        view: 'office.pms.configuration',
        system_groups: {
          view: 'office.pms.configuration.system_groups.view',
          edit: 'office.pms.configuration.system_groups.edit'
        },
        system_attributes: {
          view: 'office.pms.configuration.system_attributes.view',
          edit: 'office.pms.configuration.system_attributes.edit'
        },
        system_job_types: {
          view: 'office.pms.configuration.system_job_types.view',
          edit: 'office.pms.configuration.system_job_types.edit'
        }
      }
    },
    purchasing: {
      budget_quantity: {
        view: 'office.purchasing.budget_quantity'
      },
      requisitions: {
        view: 'office.purchasing.requisitions',
        create: 'office.purchasing.requisitions.create',
        edit: 'office.purchasing.requisitions.create',
        delete: 'office.purchasing.requisitions.delete',
        submit: 'office.purchasing.requisitions.submit',
        include_in_budget: 'office.purchasing.requisitions.include_in_budget',
        change_requisition_category: 'office.purchasing.requisitions.change_requisition_category',
        suppliers: {
          assign: 'office.purchasing.requisitions.suppliers.assign'
        },
        items: {
          // Have permissions in the right order (check func getSortedEntities)
          view: 'office.purchasing.requisitions.items',
          add: 'office.purchasing.requisitions.items.create',
          add_any_status: 'office.purchasing.requisitions.items.create_any_status',
          remove: 'office.purchasing.requisitions.items.delete',
          replace: 'office.purchasing.requisitions.items.replace',
          replace_any_status: 'office.purchasing.requisitions.items.replace_any_status',
          edit_revised_quantity: 'office.purchasing.requisitions.items.update_revised_quantity',
          edit_quotation: 'office.purchasing.requisitions.items.edit_quotation',
          edit_proposed_quantity: 'office.purchasing.requisitions.items.edit_proposed_quantity',
          edit_approved_quantity_any_status:
            'office.purchasing.requisitions.items.edit_approved_quantity_any_step',
          edit_delivered_quantity: 'office.purchasing.requisitions.items.edit_delivered_quantity',
          edit_at_any_time:
            'office.purchasing.requisitions.items.full_edit_permission_on_items_any_status'
        },
        vessels: {
          delivery_report: {
            approve: 'office.purchasing.requisitions.vessel.delivery.approve',
            edit: 'office.purchasing.requisitions.vessel.delivery.edit'
          }
        }
      },
      forwarding: {
        view: 'office.purchasing.forwarding'
      },
      library_setup: {
        view: 'office.purchasing.library_setup'
      },
      stores_inventory: {
        view: 'office.purchasing.stores_inventory'
      },
      categories: {
        view: 'office.purchasing.categories'
      },
      packing: {
        view: 'office.purchasing.packing'
      },
      pricelist: {
        view: 'office.purchasing.pricelist'
      },
      reports: {
        view: 'office.purchasing.reports'
      }
    },
    accounting: {
      mga: {
        view: 'office.accounting.mga'
      },
      invoices: {
        view: 'office.accounting.invoices.view',
        create: 'office.accounting.invoices.create',
        delete: 'office.accounting.invoices.delete',
        edit_main_info: 'office.accounting.invoices.edit_main_info',
        edit_is_paid: 'office.accounting.invoices.edit_is_paid',
        edit_sent_to_accounting: 'office.accounting.invoices.edit_sent_to_accounting',
        edit_accounting_fields: 'office.accounting.invoices.edit_accounting_fields',
        edit_marked_as_good_payment: 'office.accounting.invoices.edit_marked_as_good_payment',
        mark_as_good_payment: 'office.accounting.invoices.mark_as_good_payment',
        link_with_cases: 'office.accounting.invoices.link_with_cases',
        trigger_review_process: 'office.accounting.invoices.trigger_review_process',
        send_to_accounting: 'office.accounting.invoices.send_to_accounting'
      },
      contracts: {
        view: 'office.accounting.contracts.view',
        create: 'office.accounting.contracts.create',
        edit: 'office.accounting.contracts.edit',
        ai: 'office.accounting.contracts.ai'
      },
      reports: {
        view: 'office.accounting.reports'
      }
    },
    it: {
      view: 'office.it',
      dashboard: {
        view: 'office.it.dashboard'
      },
      integration_logs: {
        view: 'office.it.integration_logs'
      }
    },
    news_and_announcements: {
      view: 'office.news_and_announcements'
    },
    settings: {
      view: 'office.settings',
      indexes: {
        view: 'office.settings.market_indexes.view',
        values: {
          create: 'office.settings.market_indexes.values.create',
          edit: 'office.settings.market_indexes.values.edit',
          delete: 'office.settings.market_indexes.values.delete'
        }
      },
      onboard_setup: {
        view: 'office.settings.onboard_setup',
        filter_presets: {
          view: 'office.settings.onboard_setup.filter_presets.view',
          edit: 'office.settings.onboard_setup.filter_presets.edit'
        }
      },
      review_process_setup: {
        view: 'office.settings.review_process_setup.view',
        edit: 'office.settings.review_process_setup.edit'
      },
      accounting: {
        accounting_coa: {
          view: 'office.settings.accounting.accounts.view',
          edit: 'office.settings.accounting.accounts.edit'
        },
        accounting_coa_groups: {
          view: 'office.settings.accounting.chart_of_account_groups.view',
          edit: 'office.settings.accounting.chart_of_account_groups.edit'
        },
        budget: {
          view: 'office.settings.accounting.budget'
        },
        budget_scenarios: {
          view: 'office.settings.accounting.budget_scenarios.view',
          edit: 'office.settings.accounting.budget_scenarios.edit'
        },
        companies: {
          view: 'office.settings.accounting.companies'
        },
        groups_and_items_for_mga: {
          view: 'office.settings.accounting.groups_and_items_for_mga'
        },
        mga_action_types: {
          view: 'office.settings.accounting.mga_action_types'
        },
        mga_coa: {
          view: 'office.settings.accounting.mga_coa'
        },
        medical_expenses: {
          view: 'office.settings.accounting.medical_expenses'
        },
        budgeting_reports: {
          view: 'office.settings.accounting.budgeting_reports.view',
          edit: 'office.settings.accounting.budgeting_reports.edit'
        },
        contract_types: {
          view: 'office.settings.accounting.contract_type.view',
          edit: 'office.settings.accounting.contract_type.edit'
        }
      },
      case_tags: {
        view: 'office.settings.case_tags.view',
        edit: 'office.settings.case_tags.edit'
      },
      company_types: {
        view: 'office.settings.company_types.view',
        edit: 'office.settings.company_types.edit'
      },
      captain_report_templates: {
        view: 'office.settings.cpt_report_templates.view',
        edit: 'office.settings.cpt_report_templates.edit'
      },
      crew: {
        crew_matrix: {
          view: 'office.settings.crew.crew_matrix'
        },
        documents: {
          view: 'office.settings.crew.documents'
        },
        evaluation_criteria: {
          view: 'office.settings.crew.evaluation_criteria'
        },
        evaluation_templates: {
          view: 'office.settings.crew.evaluation_templates.view',
          edit: 'office.settings.crew.evaluation_templates.edit'
        },
        evaluation_obligation_templates: {
          view: 'office.settings.crew.evaluation_obligation_templates'
        },
        holidays: {
          view: 'office.settings.crew.holidays'
        },
        ranks: {
          view: 'office.settings.crew.ranks.view',
          edit_permission_policies: 'office.settings.crew.ranks.edit_permissions'
        },
        skills: {
          view: 'office.settings.crew.skills'
        },
        termination_reasons: {
          view: 'office.settings.crew.termination_reasons'
        },
        ticket_types: {
          view: 'office.settings.crew.ticket_types'
        },
        trainings: {
          view: 'office.settings.crew.trainings'
        },
        wages: {
          view: 'office.settings.crew.wages'
        }
      },
      events: {
        accounts_categories: {
          view: 'office.settings.events.account_categories'
        },
        audit_types: {
          view: 'office.settings.events.audits_and_inspections'
        },
        statuses: {
          view: 'office.settings.events.event_statuses'
        },
        templates: {
          view: 'office.settings.events.event_templates'
        },
        off_hire: {
          view: 'office.settings.events.off_hire_reasons.view',
          create: 'office.settings.events.off_hire_reasons.create',
          edit: 'office.settings.events.off_hire_reasons.edit',
          delete: 'office.settings.events.off_hire_reasons.delete'
        },
        lub_oil_analysis: {
          view: 'office.settings.events.lub_oil_analysis'
        },
        underwater_services_types: {
          view: 'office.settings.events.underwater_services'
        },
        medical_cases: {
          view: 'office.settings.events.crew_medical_cases'
        }
      },
      forms: {
        form_fields: {
          view: 'office.settings.forms.digital_form_fields.view',
          edit: 'office.settings.forms.digital_form_fields.edit',
          create: 'office.settings.forms.digital_form_fields.create'
        },
        planning: {
          view: 'office.settings.forms.form_planning.view',
          edit: 'office.settings.forms.form_planning.edit',
          delete: 'office.settings.forms.form_planning.delete',
          create: 'office.settings.forms.form_planning.edit'
        },
        forms_setup: {
          view: 'office.settings.forms.form_setup.view',
          clone: 'office.settings.forms.form_setup.clone',
          download: 'office.settings.forms.form_setup.view',
          delete: 'office.settings.forms.form_setup.delete',
          create: 'office.settings.forms.form_setup.create',
          edit: 'office.settings.forms.form_setup.edit',
          generate_new_version: 'office.settings.forms.form_setup.generate_new_version'
        },
        statuses: {
          view: 'office.settings.forms.form_statuses.view',
          create: 'office.settings.forms.form_statuses.create',
          edit: 'office.settings.forms.form_statuses.edit',
          delete: 'office.settings.forms.form_statuses.delete'
        },
        form_obligations: {
          view: 'office.settings.forms.form_obligations.view',
          edit: 'office.settings.forms.form_obligations.edit',
          create: 'office.settings.forms.form_obligations.create',
          arhive: 'office.settings.forms.form_obligations.archive'
        }
      },
      hsqe: {
        drills: {
          view: 'office.settings.hsqe.drills'
        },
        risk_assessment: {
          view: 'office.settings.hsqe.risk_assessment'
        },
        root_cause_analysis: {
          view: 'office.settings.hsqe.root_cause_analysis'
        },
        trainings: {
          view: 'office.settings.hsqe.trainings'
        },
        vetting_setup: {
          view: 'office.settings.hsqe.vetting_setup'
        }
      },
      jobs_configuration: {
        fields_setup: {
          view: 'office.settings.jobs.fields_setup.view',
          edit: 'office.settings.jobs.fields_setup.edit'
        },
        statuses: {
          view: 'office.settings.jobs.job_statuses.view',
          create: 'office.settings.jobs.job_statuses.edit',
          edit: 'office.settings.jobs.job_statuses.edit'
        },
        templates: {
          view: 'office.settings.jobs.templates.view',
          create: 'office.settings.jobs.templates.edit',
          edit: 'office.settings.jobs.templates.edit'
        },
        triggers: {
          view: 'office.settings.jobs.triggers.view',
          edit: 'office.settings.jobs.triggers.edit'
        },
        findings: {
          view: 'office.settings.jobs.findings.view',
          edit: 'office.settings.jobs.findings.edit'
        }
      },
      news_and_announcements: {
        view: 'office.settings.news_and_announcements'
      },
      application_access: {
        view: 'office.settings.application_access',
        users: {
          view: 'office.settings.application_access.users'
        },
        departments: {
          view: 'office.settings.application_access.departments.view',
          edit: 'office.settings.application_access.departments.edit',
          create: 'office.settings.application_access.departments.edit',
          delete: 'office.settings.application_access.departments.edit',
          edit_permission_policies:
            'office.settings.application_access.departments.edit_permissions'
        },
        roles: {
          view: 'office.settings.application_access.roles.view',
          edit_permission_policies: 'office.settings.application_access.roles.edit_permissions'
        },
        notification_preferences: {
          edit: 'office.settings.application_access.notification_preferences.any'
        }
      },
      vessels: {
        vessel_groups: {
          view: 'office.settings.vessels.vessel_groups.view',
          create: 'office.settings.vessels.vessel_groups.edit',
          edit: 'office.settings.vessels.vessel_groups.edit',
          delete: 'office.settings.vessels.vessel_groups.edit'
        },
        document_types: {
          view: 'office.settings.vessels.documents'
        },
        reminders_vessels_documents: {
          view: 'office.settings.vessels.document_reminders.view',
          create: 'office.settings.vessels.document_reminders.edit',
          edit: 'office.settings.vessels.document_reminders.edit'
        }
      },
      voyages: {
        view: 'office.settings.voyages',
        da_items: {
          view: 'office.settings.voyages.da_items.view',
          edit: 'office.settings.voyages.da_items.edit'
        },
        freight_collection_items: {
          view: 'office.settings.voyages.freight_hire_collection_items.view',
          edit: 'office.settings.voyages.freight_hire_collection_items.edit',
          delete: 'office.settings.voyages.freight_hire_collection_items.delete',
          create: 'office.settings.voyages.freight_hire_collection_items.edit'
        }
      }
    }
  },
  onboard: {
    signatures: {
      view: 'vessel.signatures.sign'
    },
    crew: {
      list: {
        view: 'vessel.crew.list'
      },
      seaman_profile: {
        view: 'vessel.crew.seaman_profile',
        evaluations: {
          all_users: {
            view: 'vessel.crew.evaluations_any.view',
            create: 'vessel.crew.evaluations_any.create',
            recommend_for_promotion: 'vessel.crew.evaluations_any.recommend_for_promotion',
            recommend_for_reemployment: 'vessel.crew.evaluations_any.recommend_for_reemployment',
            send_to_captain_for_review: 'vessel.crew.evaluations_any.send_to_captain_for_review',
            send_to_seaman_for_consent: 'vessel.crew.evaluations_any.send_to_evaluatee_for_consent',
            view_submission_notes: 'vessel.crew.evaluations_any.view_submission_notes',
            submit_to_office: 'vessel.crew.evaluations_any.submit_to_office'
          },
          own_profile: {
            view: 'vessel.crew.evaluations_own'
          }
        },
        personal: {
          all_users: {
            view: 'vessel.crew.seaman_profile.personal_any',
            main_info: 'vessel.crew.seaman_profile.personal_any.main_info',
            marital_status: 'vessel.crew.seaman_profile.personal_any.marital_status',
            languages: 'vessel.crew.seaman_profile.personal_any.languages',
            contact_info: 'vessel.crew.seaman_profile.personal_any.contact_info',
            nok: 'vessel.crew.seaman_profile.personal_any.nok',
            socials: 'office.crew.seaman_profile.personal.socials'
          },
          own_profile: {
            view: 'vessel.crew.seaman_profile.personal_own',
            main_info: 'vessel.crew.seaman_profile.personal_own.main_info',
            marital_status: 'vessel.crew.seaman_profile.personal_own.marital_status',
            languages: 'vessel.crew.seaman_profile.personal_own.languages',
            contact_info: 'vessel.crew.seaman_profile.personal_own.contact_info',
            nok: 'vessel.crew.seaman_profile.personal_own.nok',
            socials: 'vessel.crew.seaman_profile.personal_own.socials'
          }
        },
        documents: {
          all_users: {
            view: 'vessel.crew.documents_any.view',
            edit: 'vessel.crew.documents_any.edit'
          },
          own_profile: {
            view: 'vessel.crew.documents_own.view',
            edit: 'vessel.crew.documents_own.edit'
          }
        },
        contracts: {
          all_users: {
            view: 'vessel.crew.contracts_any'
          },
          own_profile: {
            view: 'vessel.crew.contracts_own'
          },
          sign_on_date: {
            edit: 'vessel.crew.contracts.sign_on_date.edit'
          },
          sign_off_date: {
            edit: '  vessel.crew.contracts.sign_off_date.edit'
          }
        },
        security: {
          all_users: {
            edit_password: 'vessel.crew.change_password_any'
          },
          own_profile: {
            edit_password: 'vessel.crew.change_password_own'
          }
        }
      },
      evaluation_obligations: {
        view: 'vessel.crew_evaluation_obligations'
      },
      watchkeeping: {
        view: 'vessel.watchkeeping',
        shifts: {
          view: 'vessel.watchkeeping.shifts.view',
          edit: 'vessel.watchkeeping.shifts.edit'
        },
        working_schedule: {
          view: 'vessel.watchkeeping.working_schedule.view',
          edit: 'vessel.watchkeeping.working_schedule.edit'
        }
      }
    },
    captain_reports: {
      view: 'vessel.captain_reports'
    },
    pms: {
      running_hours: { view: 'vessel.pms.running_hours' },
      jobs: {
        view: 'vessel.pms.jobs'
      },
      spare_parts_inventory: {
        view: 'vessel.pms.spare_parts_inventory.view',
        create: 'vessel.pms.spare_parts_inventory.create'
      }
    },
    forms: {
      view: 'vessel.forms',
      delete: 'vessel.forms.delete',
      list: {
        view: 'vessel.forms.list'
      },
      statuses: {
        edit: 'vessel.forms.edit_status'
      }
    },
    jobs: {
      edit: 'vessel.jobs.list', // Temp permissions for jobs edit
      list: {
        view: 'vessel.jobs.list',
        regular: {
          view: 'vessel.jobs.list.regular'
        },
        flag_extension_and_dispensation: {
          view: 'vessel.jobs.list.flag_extension_and_dispensation'
        },
        findings: {
          view: 'vessel.jobs.list.findings'
        }
      }
    },
    manuals: {
      view: 'vessel.manuals'
    },
    files: {
      view: 'vessel.files'
    },
    events: {
      view: 'vessel.events'
    },
    purchasing: {
      stores_inventory: {
        view: 'vessel.purchasing.stores_inventory.view',
        create: 'vessel.purchasing.stores_inventory.create'
      },
      deliverables: {
        view: 'vessel.purchasing.deliveries.list',
        submit: 'vessel.purchasing.deliveries.submit'
      },
      requisitions: {
        // Have permissions in the right order (check func getSortedEntities)
        default: 'vessel.purchasing.requisitions',
        view: 'vessel.purchasing.requisitions.view',
        create: 'vessel.purchasing.requisitions.create',
        edit: 'vessel.purchasing.requisitions.create',
        delete: 'vessel.purchasing.requisitions.delete',
        submit: 'vessel.purchasing.requisitions.submit',
        add_edit_items: 'vessel.purchasing.requisitions.add_edit_items',
        add_out_of_list_items: 'vessel.purchasing.requisitions.add_out_of_list_items'
      }
    },
    accounting: {
      mga: {
        current: {
          view: 'vessel.mga.view_current'
        },
        past: {
          view: 'vessel.mga.view_past'
        }
      }
    },
    vessel: {
      view: 'vessel.vessel',
      info: {
        view: 'vessel.vessel.view_info'
      },
      documents: {
        view: 'vessel.vessel.view_vessel_documents',
        edit: 'vessel.vessel.edit_vessel_documents'
      }
    },
    dashboard: {
      reports_widget: {
        card: 'vessel.dashboard.card_reports_widget'
      },
      inventory_survey_overdue: {
        card: 'vessel.dashboard.card_inventory_survey_overdue'
      },
      forms_widget: {
        card: 'vessel.dashboard.card_forms_widget',
        list: 'vessel.dashboard.list_forms'
      },
      crew_switches_widget: {
        card: 'vessel.dashboard.card_crew_switches',
        list: 'vessel.dashboard.list_crew_switches'
      },
      evaluations_widget: {
        list: 'vessel.dashboard.list_evaluations'
      },
      events_widget: {
        list: 'vessel.dashboard.list_events'
      },
      jobs_widget: {
        card: 'vessel.dashboard.card_jobs',
        list: 'vessel.dashboard.list_jobs'
      },
      running_hours_report_widget: {
        card: 'vessel.dashboard.card_running_hours_report'
      },
      findings_widget: {
        card: 'vessel.dashboard.card_findings',
        list: 'vessel.dashboard.list_findings'
      },
      purchasing_cases_widget: {
        card: 'vessel.dashboard.card_purchasing_cases',
        list: 'vessel.dashboard.list_purchasing_cases'
      }
    },
    news_and_announcements: {
      view: 'vessel.news_and_announcements'
    },
    voyages: {
      itinerary: {
        view: 'vessel.voyages.itinerary.view',
        edit: 'vessel.voyages.itinerary.add_edit_delete'
      }
    }
  }
};

export default permissions;
