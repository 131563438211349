import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';
import _get from 'lodash/get';
import { isAuthorized } from 'utils/permissions/authorize';
import { selectAccount } from 'store/account/selectors';
import { jobsNavigationTabs } from 'common/components/jobs/regular/components/NavTabs';
import { contactsNavigationTabs } from 'views/persons/NavTabs';
import voyageOperationNavigationtabs from 'views/voyages/_components/_tabs';
import { tabs as manualTabs } from '@/manuals/components/NavTabs';
import { getTabs } from '@/common/components/forms/_components/SwitchButtonGroup.tsx';

import projects from 'common/assets/svg/common/projects.svg';
import rolodex from 'assets/svg/sidebar/rolodex.svg';
import vessels from 'assets/svg/sidebar/vessels.svg';
import voyages from 'common/assets/svg/common/voyages.svg';
import estimator from 'assets/svg/sidebar/estimator.svg';
import market_indexes from 'assets/svg/sidebar/market_indexes.svg';
import bunkering_prices from 'assets/svg/sidebar/bunkering_prices.svg';
import portIcon from 'assets/svg/sidebar/ports.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import captainReports from 'common/assets/svg/common/crew.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import manuals from 'common/assets/svg/common/manuals.svg';
import theFleet from 'assets/svg/sidebar/the-fleet.svg';
import documentManager from 'assets/svg/sidebar/document_manager.svg';
import ticketing from 'assets/svg/sidebar/ticketing.svg';
import events from 'common/assets/svg/common/events.svg';
import reports from 'common/assets/svg/common/reports.svg';
import forms from 'common/assets/svg/common/forms.svg';
import list from 'common/assets/svg/common/list.svg';
import pms from 'common/assets/svg/common/setup.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import categories from 'common/assets/svg/common/categories.svg';
import units from 'common/assets/svg/common/units.svg';
import library from 'common/assets/svg/common/library.svg';
import star from 'common/assets/svg/common/star.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import budget from 'common/assets/svg/accounting/budget.svg';
import invoices from 'common/assets/svg/common/invoices.svg';
import accountingContracts from '@/common/assets/svg/common/accounting-contracts.svg';
import runningHours from 'common/assets/svg/common/reminder.svg';
import forwarding from 'common/assets/svg/common/forwarding.svg';
import inventory from 'common/assets/svg/accounting/inventory.svg';
import settings from 'common/assets/svg/actions/settings.svg';
import performance from 'assets/svg/common/performance.svg';
import environmental from 'common/assets/svg/common/earth-solid.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import calendar from 'common/assets/svg/common/calendar-bordered.svg';
import pricelist from 'common/assets/svg/common/pricelist.svg';
import contracts from 'common/assets/svg/crew/contracts.svg';
import daCases from '@/common/assets/svg/voyages/da-items.svg';
import claimExpensesIcon from '@/common/assets/svg/common/claims_expenses.svg';
import jobsConfiguration from 'assets/svg/sidebar/jobs_configuration.svg';
import templates from 'common/assets/svg/common/templates.svg';
// Use groupPath to highlight a specific menu item for every other route that starts with the same path

const items = [
  {
    path: '/',
    icon: theFleet,
    name: 'The Fleet',
    label: 'the-fleet',
    permissions: [permissions.office.fleet.view]
  },
  {
    icon: events,
    name: 'Events',
    label: 'events',
    submenu: [
      {
        path: paths.events,
        icon: list,
        name: 'Event List',
        label: 'events',
        permissions: [permissions.office.events.list.view]
      },
      {
        path: paths.events_calendar,
        icon: calendar,
        name: 'Calendar',
        permissions: [permissions.office.events.calendar.view]
      },
      {
        path: paths.reports_event,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.events.reports.view]
      }
    ]
  },
  {
    selectPath: state =>
      _get(
        jobsNavigationTabs.filter(tab => isAuthorized(selectAccount(state), tab.permissions)),
        '[0].path'
      ) || paths.regular_jobs,
    icon: jobs,
    name: 'Jobs & Findings',
    label: 'jobs',
    permissions: [permissions.office.jobs.list.view]
  },
  {
    path: paths.projects,
    icon: projects,
    name: 'Projects',
    label: 'projects',
    permissions: [permissions.office.projects.list]
  },
  {
    selectPath: state =>
      _get(
        contactsNavigationTabs.filter(tab => isAuthorized(selectAccount(state), tab.permissions)),
        '[0].to'
      ) || paths.persons,
    icon: rolodex,
    name: 'Contacts',
    label: 'rolodex',
    permissions: [
      permissions.office.contacts.persons.view,
      permissions.office.contacts.companies.view
    ]
  },
  {
    icon: vessels,
    name: 'Vessels',
    label: 'vessels',
    submenu: [
      {
        path: paths.vessels,
        icon: list,
        name: 'Vessels List',
        permissions: [permissions.office.vessels.list]
      },
      {
        path: paths.vessels_document_manager,
        icon: documentManager,
        name: 'Document Manager',
        permissions: [permissions.office.vessels.document_manager.view]
      }
    ]
  },
  {
    icon: voyages,
    name: 'Voyages',
    label: 'voyages',
    submenu: [
      {
        icon: voyages,
        name: 'Operations',
        selectPath: state => {
          const tab = _get(
            voyageOperationNavigationtabs.filter(tab =>
              isAuthorized(selectAccount(state), tab.permissions)
            ),
            '[0]'
          );

          return tab ? tab.selectMenuPath(state) : paths.voyages;
        },
        permissions: [
          permissions.office.voyages.itinerary.view,
          permissions.office.voyages.charter_parties.view,
          permissions.office.voyages.charter_parties.hires
        ]
      },
      {
        path: paths.port_da_cases,
        icon: daCases,
        name: 'DA',
        permissions: [permissions.office.voyages.da.view]
      },
      {
        path: paths.freight_hire,
        icon: claimExpensesIcon,
        name: 'Freight & Hire',
        permissions: [permissions.office.voyages.freight_hire.view]
      },
      {
        path: paths.captain_reports,
        icon: captainReports,
        label: 'captain_reports',
        name: 'Captain Reports',
        permissions: [permissions.office.voyages.captain_reports.list.view]
      },
      {
        path: paths.estimations,
        icon: estimator,
        name: 'Estimations',
        permissions: [permissions.office.voyages.estimations.list.view]
      },
      {
        path: paths.market_indexes,
        icon: market_indexes,
        name: 'Market Indexes',
        permissions: [permissions.office.voyages.market_indexes.view]
      },
      {
        path: paths.ports,
        icon: portIcon,
        name: 'Ports',
        permissions: [permissions.office.voyages.ports.view]
      },
      {
        path: paths.bunker_prices,
        icon: bunkering_prices,
        name: 'Bunker Prices',
        permissions: [permissions.office.voyages.bunker_prices.view]
      },
      {
        path: paths.reports_voyage,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.voyages.reports.view]
      }
    ]
  },
  {
    icon: performance,
    name: 'Performance',
    label: 'performance',
    submenu: [
      {
        path: paths.reports_performance,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.performance.reports.view]
      },
      {
        path: paths.reports_environmental,
        icon: environmental,
        name: 'Environmental',
        permissions: [permissions.office.performance.environmental.view]
      },
      {
        icon: settings,
        name: 'Setups',
        path: paths.performance_setups,
        permissions: [permissions.office.performance.setups.view]
      }
    ]
  },
  {
    path: paths.form_planning,
    icon: forms,
    label: 'forms',
    name: 'Forms',
    selectPath: state => {
      const formTabs = getTabs(state.isOnBoard);
      const tab = formTabs.find(tab => isAuthorized(selectAccount(state), tab.permissions));

      return tab ? tab.to : paths.form_planning;
    },
    permissions: [
      permissions.office.forms.planning.view,
      permissions.office.forms.list.view,
      permissions.office.forms.obligations.view
    ]
  },
  {
    icon: manuals,
    name: 'Manuals',
    selectPath: state => {
      const tab = manualTabs.find(tab => isAuthorized(selectAccount(state), tab.permissions));

      return tab ? tab.to : paths.manuals;
    },
    label: 'manuals',
    permissions: [permissions.office.manuals.list.view, permissions.office.files.view]
  },
  {
    icon: crewPlanning,
    name: 'Crew',
    label: 'crew',
    submenu: [
      {
        path: paths.crew,
        icon: rolodex,
        name: 'Seamen',
        permissions: [permissions.office.crew.seamen_list.view]
      },
      {
        path: paths.crew_list,
        icon: list,
        name: 'Crew List',
        permissions: [permissions.office.crew.crew_list.view]
      },
      {
        path: `${paths.crew_planning}`,
        icon: crewPlanning,
        name: 'Crew Planning',
        permissions: [permissions.office.crew.crew_planning.view]
      },
      {
        path: `${paths.ticketing}/crew`,
        icon: ticketing,
        name: 'Ticketing',
        permissions: [permissions.office.crew.ticketing.view]
      },
      {
        path: paths.crew_document_manager,
        icon: documentManager,
        name: 'Document Manager',
        permissions: [permissions.office.crew.document_manager.view]
      },
      {
        path: paths.crew_seamen_contracts,
        icon: contracts,
        name: 'Seamen Contracts',
        permissions: [permissions.office.crew.contracts.view]
      },
      {
        path: paths.crew_evaluation_obligations,
        icon: star,
        name: 'Evaluation Obligations',
        permissions: [permissions.office.crew.evaluation_obligations.view]
      },
      {
        path: paths.crew_watchkeeping,
        icon: clock,
        name: 'Watchkeeping',
        permissions: [permissions.office.crew.watchkeeping.view]
      },
      {
        path: paths.reports_crew,
        icon: reports,
        label: 'reports_crew',
        name: 'Reports',
        permissions: [permissions.office.crew.reports.view]
      }
    ]
  },
  {
    icon: pms,
    name: 'PMS',
    label: 'pms',
    certified: 'Orca PMS V1.2.9 Certified',
    submenu: [
      {
        label: 'pms_jobs_per_system',
        path: paths.pms_jobs_per_system,
        icon: jobs,
        name: 'Jobs',
        groupPath: paths.pms_jobs_label,
        permissions: [permissions.office.pms.jobs.view]
      },
      {
        label: 'pms',
        icon: pms,
        path: `${paths.pms_library}`,
        name: 'Library',
        permissions: [permissions.office.pms.library.view]
      },
      {
        label: 'pms_configuration',
        icon: jobsConfiguration,
        path: `${paths.pms_configuration}/system-groups`,
        name: 'Configuration',
        permissions: [permissions.office.pms.configuration.view]
      },
      {
        label: 'reports_pms',
        path: paths.reports_pms,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.pms.reports.view]
      },
      {
        label: 'pms_running_hours',
        path: paths.pms_running_hours,
        icon: runningHours,
        name: 'Running Hours',
        permissions: [permissions.office.pms.running_hours.view]
      },
      {
        label: 'inventory',
        icon: inventory,
        name: 'Spare Parts Inventory',
        path: paths.pms_inventory,
        permissions: [permissions.office.pms.spare_parts_inventory.view]
      }
    ]
  },
  {
    icon: purchasing,
    name: 'Purchasing',
    label: 'purchasing',
    submenu: [
      {
        path: paths.purchasing_requisitions,
        icon: list,
        name: 'Case List',
        permissions: [permissions.office.purchasing.requisitions.view]
      },
      {
        path: `${paths.purchasing_forwarding_items}`,
        icon: forwarding,
        name: 'Forwarding',
        permissions: [permissions.office.purchasing.forwarding.view]
      },
      {
        path: paths.purchasing_library_setup,
        icon: library,
        name: 'Library Setup',
        permissions: [permissions.office.purchasing.library_setup.view]
      },
      {
        path: paths.purchasing_inventory,
        icon: inventory,
        name: 'Stores Inventory',
        permissions: [permissions.office.purchasing.stores_inventory.view]
      },
      {
        path: paths.purchasing_categories,
        icon: categories,
        name: 'Categories',
        permissions: [permissions.office.purchasing.categories.view]
      },
      {
        path: paths.purchasing_packing,
        icon: units,
        name: 'Measurement Units',
        permissions: [permissions.office.purchasing.packing.view]
      },
      {
        path: paths.purchasing_pricelist,
        icon: pricelist,
        name: 'Pricelist',
        permissions: [permissions.office.purchasing.pricelist.view]
      },
      {
        path: paths.purchasing_budgeted_quantity,
        icon: budget,
        name: 'Budgeted Quantity',
        permissions: [permissions.office.purchasing.budget_quantity.view]
      },
      {
        path: paths.reports_purchasing,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.purchasing.reports.view]
      },
      {
        path: paths.purchasing_requisition_templates,
        icon: templates,
        name: 'Templates',
        permissions: [permissions.office.purchasing.requisitions.view]
      }
    ]
  },
  {
    label: 'accounting',
    icon: accounting,
    name: 'Accounting',
    submenu: [
      {
        path: paths.mga,
        icon: accounting,
        name: 'MGA',
        groupPath: paths.mga,
        permissions: [permissions.office.accounting.mga.view]
      },
      {
        path: `${paths.accounting_invoices}`,
        icon: invoices,
        name: 'Invoices',
        permissions: [permissions.office.accounting.invoices.view]
      },
      {
        path: `${paths.accounting_contracts}`,
        icon: accountingContracts,
        name: 'Contracts',
        permissions: [permissions.office.accounting.contracts.view]
      },
      {
        path: `${paths.reports_accounting}`,
        icon: reports,
        name: 'Reports',
        permissions: [permissions.office.accounting.reports.view]
      }
    ]
  }
];

export default items;
