import { GroupType } from '@/common/types/report-bulder.ts';
import { budgetingReportBuilderEnums } from '@/common/utils/fixed.tsx';
import { AccountingAccount, AccountingCoaGroup } from '@/common/types/accounting.ts';
import { DataState, ReportSetupState, SubItemsState } from '@/store/report-setup/slice.ts';
import { GroupTypeWithAccountIdsType } from '@/api/budgeting-reports/api';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';

export type ReportGroupId = number | string;

export type ReportGroupStateData = {
  id: ReportGroupId;
  group_id: number | null;
  budgeting_report_id: number;
  name: string;
  sort_index: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  depth: number;
  group_type: (typeof budgetingReportBuilderEnums)[keyof typeof budgetingReportBuilderEnums];
  is_new?: boolean;
  account: AccountingAccount | null;
  account_id: number | null;
  account_group: AccountingCoaGroup | null;
  account_group_id: number | null;
};

type FlattenedItem = {
  id: ReportGroupId;
  group_id: number | null;
  budgeting_report_id: number;
  name: string;
  sort_index: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  depth: number;
  group_type: (typeof budgetingReportBuilderEnums)[keyof typeof budgetingReportBuilderEnums];
  account: AccountingAccount | null;
  account_id: number | null;
  account_group: AccountingCoaGroup | null;
  account_group_id: number | null;
};

// Recursive function to remove the given id and its children
export const removeGroupRecursively = (state: ReportSetupState, id: ReportGroupId): void => {
  // If the item has sub-items, remove them recursively
  if (state.groups.subItems[id]) {
    state.groups.subItems[id].forEach((subId: ReportGroupId) => {
      removeGroupRecursively(state, subId); // Recursive call for nested sub-items
    });

    // Delete the sub-item entry after all sub-items are removed
    delete state.groups.subItems[id];
  }

  // Remove the ID from the data if it exists
  if (state.groups.data[id]) {
    delete state.groups.data[id];
  }

  // Remove the ID from rootItems if it exists there
  state.groups.rootItems = state.groups.rootItems.filter((rootId: ReportGroupId) => rootId !== id);

  // Remove the ID from any parent subItems entries
  Object.keys(state.groups.subItems).forEach(parentId => {
    state.groups.subItems[parentId] = state.groups.subItems[parentId]?.filter(
      (subId: ReportGroupId) => subId !== id
    );

    // If a parent has no more sub-items, remove its entry
    if (state.groups.subItems[parentId]?.length === 0) {
      delete state.groups.subItems[parentId];
    }

    // Update the parent's itemsType based on remaining sub-items
    if (state.groups.data[parentId]) {
      state.groups.data[parentId] = {
        ...state.groups.data[parentId],
        group_type: state.groups.subItems[parentId]?.length
          ? state.groups.data[parentId].group_type
          : budgetingReportBuilderEnums.group
      };
    }
  });
};

export const getLedgerCardUid = (groupId: number | string, accountId: number | string) =>
  `${groupId}_${budgetingReportBuilderEnums.ledger_card}_${accountId}`;

export const getCoAGroupUid = (groupId: number | string, accountGroupId: number | string) =>
  `${groupId}_${budgetingReportBuilderEnums.coa_group}_${accountGroupId}`;

export const flattenGroupsWithAccounts = (groups: GroupType[]) => {
  const items: FlattenedItem[] = [];

  groups.forEach(group => {
    const { accounts, account_groups, id, ...rest } = group;
    const item = {
      ...rest,
      id,
      group_id: id,
      account_id: null,
      account: null,
      account_group: null,
      account_group_id: null
    };

    items.push({ ...item, group_type: budgetingReportBuilderEnums.group });

    if (accounts.length) {
      accounts.forEach((account, index) => {
        const accountItem = {
          ...item,
          id: getLedgerCardUid(item.id, account.id),
          depth: item.depth + 1,
          parent_id: item.id,
          sort_index: index,
          name: `${account.code}. ${account.name}`,
          group_type: budgetingReportBuilderEnums.ledger_card,
          account_id: account.id,
          account
        };

        items.push(accountItem);
      });
    } else if (account_groups.length) {
      account_groups.forEach((account_group, index) => {
        const accountGroupItem = {
          ...item,
          id: getCoAGroupUid(item.id, account_group.id),
          depth: item.depth + 1,
          parent_id: item.id,
          sort_index: index,
          name: `${account_group.code}. ${account_group.name}`,
          group_type: budgetingReportBuilderEnums.coa_group,
          account_group_id: account_group.id,
          account_group
        };

        items.push(accountGroupItem);
      });
    }
  });

  return items;
};

const gatherGroupChildren = (
  data: DataState,
  parentId: ReportGroupId,
  type: (typeof budgetingReportBuilderEnums)[keyof typeof budgetingReportBuilderEnums]
) => {
  const children = _reduce(
    data,
    (result: { id: number; sort_index: number }[], group) => {
      if (group.parent_id === parentId) {
        if (type === budgetingReportBuilderEnums.ledger_card && group.account) {
          result.push({ id: group.account.id, sort_index: group.sort_index });
        } else if (type === budgetingReportBuilderEnums.coa_group && group.account_group) {
          result.push({ id: group.account_group.id, sort_index: group.sort_index });
        }
      }

      return result;
    },
    []
  );

  return _sortBy(children, 'sort_index').map(({ id }) => id);
};

export const gatherGroupItems = (
  id: ReportGroupId,
  subItems: SubItemsState,
  data: DataState,
  result: GroupTypeWithAccountIdsType[]
) => {
  const item = data[id];

  if (item.group_type === budgetingReportBuilderEnums.group) {
    const parsedItem = {
      id: item.id && !item.is_new ? (item.id as number) : undefined,
      name: item.name,
      parent_id: item.parent_id,
      account_ids:
        item.id && !item.is_new
          ? gatherGroupChildren(data, item.id, budgetingReportBuilderEnums.ledger_card)
          : [],
      account_group_ids:
        item.id && !item.is_new
          ? gatherGroupChildren(data, item.id, budgetingReportBuilderEnums.coa_group)
          : []
    };

    result.push(parsedItem); // Add the current item to the result array

    // If the item has sub-items, recursively gather them
    const children = subItems[id];

    if (children) {
      children.forEach((childId: ReportGroupId) =>
        gatherGroupItems(childId, subItems, data, result)
      );
    }
  }

  return result;
};
