import { VesselSystemBase } from '@/common/types/vessel-systems';
import { parseQueryParams, RequestParamsType } from '@webthatmatters/orca-table';
import { useAppSelector } from '@/store/hooks';
import { FC, useMemo } from 'react';
import {
  OptionWithParentIcon,
  sortVesselSystemsOptionsPerParent
} from '@/common/components/selectors/_vesselSystemSelectComponents';

import Select, { SelectProps } from '@/ts-common/components/form/inputs/select';
import useFilterQuerySelector from '@/common/components/filters/useFilterQuerySelector.ts';
import useRouter from 'use-react-router';
import { useDefaultOptionsQuery } from '@/ts-common/components/form/inputs/select/api/queries';

type ValueType = number[] | string[] | null;

type VesselSystemsCollectionFilterSelectorProps = {
  filter?: { value: ValueType };
  value?: ValueType;
  tableFilterVesselKey?: string;
  isMulti?: boolean;
  onChange: (value: string[] | null) => void;
} & SelectProps<VesselSystemBase>;

type TableVesselFilter = number[] | number | undefined | null;

const VesselSystemsCollectionFilterSelector: FC<VesselSystemsCollectionFilterSelectorProps> = ({
  filter,
  value,
  tableFilterVesselKey = 'vessel_id',
  onChange,
  isMulti = true,
  ...rest
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const { history } = useRouter();

  const requestedVesselId = useMemo(() => {
    if (isOnBoard) return undefined;

    const { filters } = parseQueryParams(history.location.search) as RequestParamsType;

    const vesselFilter = (filters &&
      filters.find(f => f.name === tableFilterVesselKey && f.value)?.value) as TableVesselFilter;

    // When a single vessel is applied, we filter the vessel-systems with this one
    if (vesselFilter && Array.isArray(vesselFilter))
      return vesselFilter.length === 1 ? vesselFilter[0] : null;
    else if (vesselFilter) return vesselFilter;

    return null;
  }, [isOnBoard, tableFilterVesselKey, history.location.search]);

  const requestParams = useMemo(
    () => ({
      enabled: true,
      path: 'lists',
      params: {
        list: 'vessel-systems',
        all_systems: true,
        include_parent: true,
        vessel_id: requestedVesselId || undefined
      }
    }),
    [requestedVesselId, isOnBoard]
  );

  const initialOptionsQuery = useDefaultOptionsQuery(requestParams);

  const selectedOptions = useFilterQuerySelector(filter?.value || value || null, {
    path: 'lists',
    params: { list: 'vessel-systems', all_systems: true }
  }) as VesselSystemBase[] | null;

  return (
    <Select
      placeholder="Select value"
      {...rest}
      getOptionValue={(option: VesselSystemBase) => option.id.toString()}
      getOptionLabel={(option: VesselSystemBase) => option.description}
      components={{ Option: OptionWithParentIcon }}
      memoizedRequestParams={requestParams}
      value={selectedOptions}
      onChange={selected => {
        if (isMulti) {
          const selectedValues = selected as VesselSystemBase[] | null;

          onChange(selectedValues?.length ? selectedValues.map(({ id }) => id.toString()) : null);
        } else {
          const selectedValue = selected as VesselSystemBase | null;

          onChange(selectedValue ? [selectedValue.id.toString()] : null);
        }
      }}
      isAsync={true}
      isMulti={isMulti}
      parseOptions={(options: VesselSystemBase[]) => sortVesselSystemsOptionsPerParent(options)}
      defaultOptions={sortVesselSystemsOptionsPerParent(
        (initialOptionsQuery.data || []) as VesselSystemBase[]
      )}
    />
  );
};

export default VesselSystemsCollectionFilterSelector;
