import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import paths from '@/routing/routes/_paths.js';
import permissions from '@/common/utils/permissions/constants.js';

const JobTemplates = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/templates/index.jsx'))
);
const JobStatus = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/status/index.jsx'))
);
const JobRestricted = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/fields/index.jsx'))
);

const CompanyTypes = React.lazy(() =>
  retry(() => import('@/views/settings/company-types/index.jsx'))
);

const CrewEvaluationTemplate = React.lazy(() =>
  retry(() => import('@/views/settings/crew/evaluation-templates/index.jsx'))
);
const CrewEvaluationTemplateForm = React.lazy(() =>
  retry(() => import('@/views/settings/crew/evaluation-templates/form/index.jsx'))
);
const CrewEvaluationCriteria = React.lazy(() =>
  retry(() => import('@/views/settings/crew/evaluation-criteria/index.jsx'))
);
const CrewEvaluationCriteriaCategories = React.lazy(() =>
  retry(() => import('@/views/settings/crew/evaluation-criteria/categories/index.jsx'))
);

const VesselGroups = React.lazy(() =>
  retry(() => import('@/views/settings/vessel-groups/index.jsx'))
);

const RemindersVesselsDocuments = React.lazy(() =>
  retry(() => import('@/views/settings/reminders/vessels-documents/index.jsx'))
);
const CrewWages = React.lazy(() => retry(() => import('@/views/settings/crew/wages/index.jsx')));
const DocumentsSettings = React.lazy(() =>
  retry(() => import('@/views/settings/crew/documents/index.jsx'))
);
const DocumentsSettingsMatrix = React.lazy(() =>
  retry(() => import('@/views/settings/crew/documents/matrix/index.jsx'))
);
const Drills = React.lazy(() => retry(() => import('@/views/settings/hsqe/drills/index.jsx')));
const TrainingsOthers = React.lazy(() =>
  retry(() => import('@/views/settings/crew/trainings/index.jsx'))
);
const TrainingsOnboard = React.lazy(() =>
  retry(() => import('@/views/settings/hsqe/trainings/index.jsx'))
);

const CrewEvaluationObligationTemplates = React.lazy(() =>
  retry(() => import('@/views/settings/crew/evaluation-obligation-templates/index.jsx'))
);

const MgaCoa = React.lazy(() => retry(() => import('@/views/settings/mga/coa/index.jsx')));
const MgaActionTypes = React.lazy(() =>
  retry(() => import('@/views/settings/mga/action-types/index.jsx'))
);
const MgaItems = React.lazy(() => retry(() => import('@/views/settings/mga/items/index.jsx')));

const SkillTypes = React.lazy(() =>
  retry(() => import('@/views/settings/crew/skill-types/index.jsx'))
);
const TicketTypes = React.lazy(() =>
  retry(() => import('@/views/settings/crew/ticket-types/index.jsx'))
);
const AccountingCompanies = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/companies/index.jsx'))
);
const AccountingContractTypes = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/contract-types/index.tsx'))
);
const VesselDocumentsCategories = React.lazy(() =>
  retry(() => import('@/views/settings/vessel-documents/categories/index.jsx'))
);
const VesselDocumentTypes = React.lazy(() =>
  retry(() => import('@/views/settings/vessel-documents/types/index.jsx'))
);

const OnboardSyncs = React.lazy(() =>
  retry(() => import('@/views/settings/onboard-syncs/index.jsx'))
);

const Indexes = React.lazy(() => retry(() => import('@/views/settings/indexes/index.tsx')));

const CrewRanks = React.lazy(() => retry(() => import('@/views/settings/crew/ranks/index.jsx')));
const CrewRankPolicies = React.lazy(() =>
  retry(() => import('@/views/settings/crew/ranks/policies/index.jsx'))
);

const Holidays = React.lazy(() => retry(() => import('@/views/settings/crew/holidays/index.jsx')));
const JobTriggers = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/triggers/index.jsx'))
);
const JobTriggersForm = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/triggers/form/index.jsx'))
);
const Findings = React.lazy(() =>
  retry(() => import('@/views/settings/jobs-and-findings/findings/index.tsx'))
);
const VettingSetup = React.lazy(() => retry(() => import('@/views/settings/vetting/index.jsx')));
const Departments = React.lazy(() => retry(() => import('@/views/settings/departments/index.jsx')));
const DepartmentPolicies = React.lazy(() =>
  retry(() => import('@/views/settings/departments/policies/index.jsx'))
);
const Roles = React.lazy(() => retry(() => import('@/views/settings/roles/index.jsx')));
const RolePolicies = React.lazy(() =>
  retry(() => import('@/views/settings/roles/policies/index.jsx'))
);

const MedicalExpenses = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/medical-expenses/index.jsx'))
);
const Matrices = React.lazy(() => retry(() => import('@/views/settings/crew/matrices/index.jsx')));
const TerminationReasons = React.lazy(() =>
  retry(() => import('@/views/settings/crew/termination-reasons/index.jsx'))
);
const NewsAnnouncements = React.lazy(() =>
  retry(() => import('@/views/settings/news-announcements/index.jsx'))
);
const NewsAnnouncementsForm = React.lazy(() =>
  retry(() => import('@/views/settings/news-announcements/form/index.jsx'))
);
const RootCauseAnalysis = React.lazy(() =>
  retry(() => import('@/views/settings/hsqe/root-cause-analysis/index.jsx'))
);
const RootCauseAnalysisCategories = React.lazy(() =>
  retry(() => import('@/views/settings/hsqe/root-cause-analysis/Categories.jsx'))
);
const Policies = React.lazy(() => retry(() => import('@/views/settings/policies/index.jsx')));

const NotificationPreferencesAdministration = React.lazy(() =>
  retry(() => import('@/views/settings/notification-preferences-administration/index.jsx'))
);

const PeopleWithAccess = React.lazy(() =>
  retry(() => import('@/views/settings/application-access/persons/index.jsx'))
);
const PersonPolices = React.lazy(() =>
  retry(() => import('@/views/settings/application-access/persons/policies/index.jsx'))
);
const CrewWithAccess = React.lazy(() =>
  retry(() => import('@/views/settings/application-access/crew/index.jsx'))
);
const CaseTags = React.lazy(() => retry(() => import('@/views/settings/case-tags/index.jsx')));

const ReviewProcessSetup = React.lazy(() =>
  retry(() => import('@/views/settings/review-process/templates/index.jsx'))
);

const LedgerCards = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/ledger-cards/index.tsx'))
);
const ChartOfAccountsGroups = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/coa-groups/index.tsx'))
);

const Budget = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/budget/index.jsx'))
);
const BudgetScenarios = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/budget-scenarios/index.jsx'))
);
const ReportSetup = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/report-setup/index.tsx'))
);
const ReportSetupForm = React.lazy(() =>
  retry(() => import('@/views/settings/accounting/report-setup/form/index.tsx'))
);

const PortDaItems = React.lazy(() =>
  retry(() => import('@/views/settings/voyages/port-da-items/index.tsx'))
);

const FreightHireCollectionItems = React.lazy(() =>
  retry(() => import('@/views/settings/voyages/freight-hire-collection-items/index.tsx'))
);

const SetupOnboardFilters = React.lazy(() =>
  retry(() => import('@/views/settings/setup-onboard/filters/index.tsx'))
);

const settings = [
  {
    path: `${paths.departments}`,
    component: Departments,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.departments.view],
    documentTitle: 'Departments Settings'
  },
  {
    path: `${paths.department_polices}/:tab(core|onboard|settings)/:id`,
    component: DepartmentPolicies,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.settings.application_access.departments.edit_permission_policies
    ],
    documentTitle: 'Department Policies'
  },
  {
    path: `${paths.department_roles}`,
    component: Roles,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.roles.view],
    documentTitle: 'Roles Settings'
  },
  {
    path: `${paths.department_role_polices}/:tab(core|onboard|settings)/:id`,
    component: RolePolicies,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.roles.edit_permission_policies],
    documentTitle: 'Role Policies'
  },
  {
    path: `${paths.persons_permissions}`,
    component: PeopleWithAccess,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.users.view],
    documentTitle: 'People with Access'
  },
  {
    path: `${paths.persons_permission_policies}/:id`,
    component: PersonPolices,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.users.view],
    documentTitle: 'Person Permission Policies'
  },
  {
    path: `${paths.crew_permissions}`,
    component: CrewWithAccess,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.application_access.users.view],
    documentTitle: 'Crew with Access'
  },
  {
    path: `${paths.jobs_status}`,
    component: JobStatus,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.statuses.view],
    documentTitle: 'Job Statuses'
  },
  {
    path: `${paths.jobs_templates}`,
    component: JobTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.templates.view],
    documentTitle: 'Job Templates'
  },
  {
    path: `${paths.jobs_templates}/archived`,
    component: JobTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.templates.view],
    documentTitle: 'Job Templates'
  },
  {
    path: `${paths.jobs_templates}/create`,
    component: JobTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.templates.create],
    documentTitle: 'Create Job Template'
  },
  {
    path: `${paths.jobs_templates}/:id/edit`,
    component: JobTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.templates.edit],
    documentTitle: 'Edit Job Template'
  },
  {
    path: `${paths.jobs_triggers}`,
    component: JobTriggers,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.triggers.view],
    documentTitle: 'Job Triggers'
  },
  {
    path: `${paths.jobs_triggers}/archived`,
    component: JobTriggers,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.triggers.view],
    documentTitle: 'Job Triggers'
  },
  {
    path: `${paths.jobs_triggers}/create`,
    component: JobTriggersForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.triggers.edit],
    documentTitle: 'Create Job Trigger'
  },
  {
    path: `${paths.jobs_triggers}/:id/edit`,
    component: JobTriggersForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.triggers.edit],
    documentTitle: 'Edit Job Trigger'
  },
  {
    path: `${paths.jobs_triggers}/:id/:step(triggers|summary)`,
    component: JobTriggersForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.triggers.view],
    documentTitle: 'Edit Job Trigger'
  },
  {
    path: `${paths.jobs_restricted}`,
    component: JobRestricted,
    app: true,
    type: 'private',
    exact: true,
    pageTitle: 'Fields Setup',
    documentTitle: 'Job Fields Setup',
    permissions: [permissions.office.settings.jobs_configuration.fields_setup.view]
  },
  {
    path: `${paths.jobs_findings}/:tab(categories|tags|subcategories)?`,
    component: Findings,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.jobs_configuration.findings.view],
    documentTitle: 'Findings'
  },
  {
    path: `${paths.case_tags}`,
    component: CaseTags,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.settings.case_tags.view,
      permissions.office.settings.case_tags.edit
    ],
    documentTitle: 'Case Tags'
  },
  {
    path: `${paths.company_types}`,
    component: CompanyTypes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.settings.company_types.view,
      permissions.office.settings.company_types.edit
    ],
    documentTitle: 'Company Types'
  },
  {
    path: paths.crew_evaluation_obligation_templates,
    component: CrewEvaluationObligationTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_obligation_templates.view],
    documentTitle: 'Crew Evaluation Obligation Templates'
  },
  {
    path: `${paths.crew_evaluation_obligation_templates}/archived`,
    component: CrewEvaluationObligationTemplates,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_obligation_templates.view],
    documentTitle: 'Crew Evaluation Obligation Templates'
  },
  {
    path: paths.crew_evaluation_criteria,
    component: CrewEvaluationCriteria,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_criteria.view],
    documentTitle: 'Crew Evaluation Criteria'
  },
  {
    path: `${paths.crew_evaluation_criteria}/archived`,
    component: CrewEvaluationCriteria,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_criteria.view],
    documentTitle: 'Crew Evaluation Criteria'
  },
  {
    path: `${paths.crew_evaluation_criteria}/categories`,
    component: CrewEvaluationCriteriaCategories,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_criteria.view],
    documentTitle: 'Crew Evaluation Criteria Categories'
  },
  {
    path: `${paths.crew_evaluation_criteria}/categories/archived`,
    component: CrewEvaluationCriteriaCategories,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_criteria.view],
    documentTitle: 'Crew Evaluation Criteria Categories'
  },
  {
    path: `${paths.crew_evaluation_templates}`,
    component: CrewEvaluationTemplate,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_templates.view],
    documentTitle: 'Crew Evaluation Templates'
  },
  {
    path: `${paths.crew_evaluation_templates}/archived`,
    component: CrewEvaluationTemplate,
    app: true,
    type: 'private',
    exact: true,
    pageTitle: 'Evaluation Templates',
    documentTitle: 'Evaluation Templates',
    permissions: [permissions.office.settings.crew.evaluation_templates.view]
  },
  {
    path: `${paths.crew_evaluation_templates}/create`,
    component: CrewEvaluationTemplateForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_templates.edit],
    documentTitle: 'Create Evaluation Template'
  },
  {
    path: `${paths.crew_evaluation_templates}/edit/:id`,
    component: CrewEvaluationTemplateForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.evaluation_templates.edit],
    documentTitle: 'Edit Evaluation Template'
  },
  {
    path: `${paths.crew_ranks}`,
    component: CrewRanks,
    app: true,
    type: 'private',
    exact: true,
    pageTitle: 'Ranks',
    documentTitle: 'Ranks',
    permissions: [permissions.office.settings.crew.ranks.view]
  },
  {
    path: `${paths.crew_rank_policies}/:tab(core|onboard|settings)/:id`,
    component: CrewRankPolicies,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Rank Policies',
    permissions: [permissions.office.settings.crew.ranks.edit_permission_policies]
  },
  {
    path: paths.crew_skill_types,
    component: SkillTypes,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Skills',
    permissions: [permissions.office.settings.crew.skills.view]
  },
  {
    path: paths.crew_termination_reasons,
    component: TerminationReasons,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.termination_reasons.view],
    documentTitle: 'Crew Termination Reasons'
  },
  {
    path: paths.crew_holidays,
    component: Holidays,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.holidays.view],
    documentTitle: 'Holidays'
  },
  {
    path: `${paths.crew_ticketing_types}`,
    component: TicketTypes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.ticket_types.view],
    documentTitle: 'Ticket Types'
  },
  {
    path: `${paths.vessel_groups}`,
    component: VesselGroups,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.vessels.vessel_groups.view],
    documentTitle: 'Vessel Groups'
  },
  {
    path: paths.accounting_medical_expenses_settings,
    component: MedicalExpenses,
    app: true,
    key: 'claims-expenses',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.medical_expenses.view],
    documentTitle: 'Medical Expenses'
  },
  {
    path: `${paths.vessel_document_types}`,
    component: VesselDocumentTypes,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.vessels.document_types.view],
    documentTitle: 'Documents'
  },
  {
    path: `${paths.vessel_document_categories}`,
    component: VesselDocumentsCategories,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.vessels.document_types.view],
    documentTitle: 'Documents'
  },
  {
    path: `${paths.vessel_document_reminders}`,
    component: RemindersVesselsDocuments,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.vessels.reminders_vessels_documents.view],
    documentTitle: 'Vessel Document Reminders'
  },
  {
    path: paths.crew_matrices,
    component: Matrices,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.crew_matrix.view],
    documentTitle: 'Crew Matrix'
  },
  {
    path: paths.crew_document_types,
    component: DocumentsSettings,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.documents.view],
    documentTitle: 'Crew Document Settings'
  },
  {
    path: `${paths.crew_document_types}/archived`,
    component: DocumentsSettings,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.documents.view],
    documentTitle: 'Crew Document Settings'
  },
  {
    path: `${paths.crew_document_types}/matrix`,
    component: DocumentsSettingsMatrix,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.documents.view],
    documentTitle: 'Crew Document Settings'
  },
  {
    path: `${paths.crew_trainings}/schedule`,
    component: TrainingsOthers,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'Crew Trainings Schedule'
  },
  {
    path: `${paths.crew_trainings}/archived`,
    component: TrainingsOthers,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'Crew Trainings'
  },
  {
    path: paths.crew_trainings,
    component: TrainingsOthers,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'Crew Trainings'
  },
  {
    path: `${paths.hsqe_trainings}/schedule`,
    component: TrainingsOnboard,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'HSQE Trainings Schedule'
  },
  {
    path: `${paths.hsqe_trainings}/archived`,
    component: TrainingsOnboard,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'HSQE Trainings'
  },
  {
    path: paths.hsqe_trainings,
    component: TrainingsOnboard,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.trainings.view],
    documentTitle: 'HSQE Trainings'
  },
  {
    path: paths.drills,
    component: Drills,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.drills.view],
    documentTitle: 'HSQE Drills'
  },
  {
    path: `${paths.drills}/archived`,
    component: Drills,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.drills.view],
    documentTitle: 'HSQE Drills'
  },
  {
    path: `${paths.drills}/schedule`,
    component: Drills,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.drills.view],
    documentTitle: 'HSQE Drills'
  },
  {
    path: paths.crew_wages,
    component: CrewWages,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.crew.wages.view],
    documentTitle: 'Crew Wages'
  },
  {
    path: paths.setup_onboard_filters,
    component: SetupOnboardFilters,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Setup Onboard Filters',
    permissions: [permissions.office.settings.onboard_setup.filter_presets.view]
  },
  {
    path: paths.news_announcements,
    component: NewsAnnouncements,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'News/Announcements',
    permissions: [permissions.office.settings.news_and_announcements.view]
  },
  {
    path: `${paths.news_announcements}/create`,
    component: NewsAnnouncementsForm,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Create News/Announcements'
  },
  {
    path: `${paths.news_announcements}/:id`,
    component: NewsAnnouncementsForm,
    app: true,
    type: 'private',
    exact: true,
    documentTitle: 'Edit News/Announcements'
  },
  {
    path: paths.onboard_syncs,
    component: OnboardSyncs,
    app: true,
    key: 'onboard-syncs',
    pageTitle: 'Onboard Syncs',
    documentTitle: 'Onboard Syncs',
    type: 'private',
    exact: true,
    environments: ['development', 'staging', 'preproduction']
  },
  {
    path: paths.indexes,
    component: Indexes,
    app: true,
    key: 'indexes',
    pageTitle: 'Indexes',
    documentTitle: 'Indexes',
    permissions: [permissions.office.settings.indexes.view],
    type: 'private',
    exact: true
  },
  {
    path: paths.accounting_companies_settings,
    component: AccountingCompanies,
    app: true,
    key: 'accounting_companies',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.companies.view],
    documentTitle: 'Companies'
  },
  {
    path: paths.accounting_contract_types_settings,
    component: AccountingContractTypes,
    app: true,
    key: 'accounting_contract_types',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.contract_types.view],
    documentTitle: 'Contract Types'
  },
  {
    path: paths.accounting_ledger_cards_settings,
    component: LedgerCards,
    app: true,
    key: 'ledger-cards',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.accounting_coa.view], // to be updated
    documentTitle: 'Ledger Cards'
  },
  {
    path: paths.accounting_coa_groups_settings,
    component: ChartOfAccountsGroups,
    app: true,
    key: 'accounting-coa-groups',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.accounting_coa_groups.view],
    documentTitle: 'Chart of Accounts Groups'
  },
  {
    path: `${paths.accounting_budget_settings}`,
    component: Budget,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.budget.view],
    documentTitle: 'Budget'
  },
  {
    path: paths.accounting_budget_scenarios_settings,
    component: BudgetScenarios,
    app: true,
    key: 'accounting-budget-scenarios',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.budget_scenarios.view],
    documentTitle: 'Budget Scenarios'
  },
  {
    path: paths.mga_coa_settings,
    component: MgaCoa,
    app: true,
    key: 'mga-coa',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.mga_coa.view],
    documentTitle: 'MGA Chart of Accounts'
  },
  {
    path: paths.budgeting_report_setup,
    component: ReportSetup,
    app: true,
    key: 'report-setup',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.budgeting_reports.view],
    documentTitle: 'Report Setup'
  },
  {
    path: [`${paths.budgeting_report_setup}/create`, `${paths.budgeting_report_setup}/:id`],
    component: ReportSetupForm,
    app: true,
    key: 'report-setup',
    type: 'private',
    exact: true,
    permissions: [
      permissions.office.settings.accounting.budgeting_reports.view,
      permissions.office.settings.accounting.budgeting_reports.edit
    ],
    documentTitle: 'Report Setup'
  },
  {
    path: paths.mga_items_settings,
    component: MgaItems,
    app: true,
    key: 'mga-items',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.groups_and_items_for_mga.view],
    documentTitle: 'Groups & Items for MGA'
  },
  {
    path: paths.mga_action_types_settings,
    component: MgaActionTypes,
    app: true,
    key: 'mga-action-typess',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.accounting.mga_action_types.view],
    documentTitle: 'MGA Action Types'
  },
  {
    path: `${paths.root_cause_analysis}/categories`,
    component: RootCauseAnalysisCategories,
    app: true,
    key: 'root-cause-analysis-categories',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.root_cause_analysis.view],
    documentTitle: 'Root Cause Analysis Categories'
  },
  {
    path: paths.root_cause_analysis,
    component: RootCauseAnalysis,
    app: true,
    key: 'root-cause-analysis',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.root_cause_analysis.view],
    documentTitle: 'Root Cause Analysis'
  },
  {
    path: paths.vetting_setup,
    component: VettingSetup,
    app: true,
    key: 'vetting-setup',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.vetting_setup.view],
    documentTitle: 'Vetting Setup'
  },
  {
    path: `${paths.policies}/:tab(core|onboard|settings)`,
    component: Policies,
    app: true,
    key: 'policies',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.hsqe.vetting_setup.view],
    documentTitle: 'Policies'
  },
  {
    path: paths.notification_preferences_administration,
    component: NotificationPreferencesAdministration,
    app: true,
    key: 'policies',
    type: 'private',
    exact: true,
    permissions: [permissions.office.contacts.persons.view],
    documentTitle: 'Notification Preferences Administration'
  },
  {
    path: paths.review_process,
    component: ReviewProcessSetup,
    app: true,
    key: 'review-process',
    type: 'private',
    exact: true,
    permissions: [permissions.office.settings.review_process_setup.view],
    documentTitle: 'Review Process Setup'
  },
  {
    path: paths.port_da_items,
    component: PortDaItems,
    app: true,
    key: 'port-da-items',
    type: 'private',
    exact: true,
    documentTitle: 'DA Items',
    permissions: [permissions.office.settings.voyages.da_items.view]
  },
  {
    path: paths.freight_hire_collection_items,
    component: FreightHireCollectionItems,
    app: true,
    key: 'freight-hire-collection-items',
    type: 'private',
    exact: true,
    documentTitle: 'Freight & Hire Items',
    permissions: [permissions.office.settings.voyages.freight_collection_items.view]
  }
];

export default settings;
