const lists = [
  'vessels',
  'vessel-types',
  'vessel-subtype',
  'vessel-subtypes',
  'vessel-prefixes',
  'vessel-situations',
  'vessel-groups',
  'vessel-groups',
  'vessel-fuel-tank-categories',
  'vessel-consumption-types',
  'vessel-contact-info-types',
  'vessel-fuel-tank-types',
  'vessel-document-categories',
  'vessel-document-types',
  'vessel-system-assignment-status',
  'vessel-systems',
  'vessel-document-category-templates',

  'fleets',
  'fleet-status',

  'people',

  'parties',
  'party-company-types',
  'party-company-subtypes',
  'party-and-crew-departments',
  'party-bank-accounts',

  'charter-party-evaluation-criteria',
  'company_supplier_evaluations',
  'company_agent_evaluations',
  'company_charterers_evaluations',

  'departments',
  'department-roles',

  'roles',
  'extension-and-dispensation-types',

  'widget-types',

  'fuel-grades',
  'fuel-grades-with-prices',

  'cp-forms',

  'units',

  'purchasing-unit-types',

  'market-indexes',
  'market-index-types',

  'misc-descriptions',

  'terms',

  'ports',

  'airports',

  'job-progress-statuses',
  'job-statuses',
  'job-template-types',
  'job-importances',
  'job-restricted-fields',
  'findings',

  'maintenance-jobs',
  'maintenance-job-types',

  'special-zones-eca',
  'special-zones-hra',
  'special-zones-vra',
  'special-zones',

  'own-reasons',

  'genders',

  'marital-statuses',

  'languages',
  'language-levels',
  'lubricant-oil-states',
  'lubricant-oil-state-engines',

  'blood-types',

  'ticketing-types',
  'ticketing-statuses',

  'criteria',
  'criteria-categories',
  'criteria-types',

  'evaluation-template-type',
  'evaluation-template-reason',

  'rotation-vessels',

  'graph-intervals',

  'scales',

  'estimations',

  'laytime-events',

  'itinerary-ports',
  'itinerary-action-types',
  'itinerary-vessels',
  'charter-party-types',
  'charter-party-broker-types',
  'charter-party-broker-rate-units',
  'charter-party-tc-hire-types',
  'charter-party-duration-types',

  'shift-types',

  'stoppage-reason',
  'stoppage-associated-element',
  'status-at-port',

  'port-areas',

  'cargo-grades',

  'report-groups',

  'schedule-intervals',

  'digital-forms',
  'form-fields',
  'form-field-types',
  'forms',
  'forms-for-vessel',
  'form-progress',
  'form-statuses',
  'forms-not-in-planning',
  'form-visibility',

  'events',
  'event-importances',
  'event-statuses',
  'event-types',
  'event-modules',
  'event-progress',
  'event-audit-types',
  'event-audits',
  'event-audit-auditor-types',
  'finding-categories',
  'finding-types',
  'finding-tags',

  'importances',

  'crew-ranks',
  'crew-ranks-officers',
  'crew-training-types',
  'crew-evaluation-templates',
  'crew-contracts',
  'crew-evaluation-reasons',
  'crew-document-categories',
  'crew-contract-sign-off-reason',
  'crew-medical-case-types',
  'aggregation-types',
  'crew-matrix-rule-types',

  'spare-parts',

  'tank-measurement-method',

  'purpose-of-call',

  'berth-type',

  'ld-stopping-reason',
  'ld-hired-gear-type',

  'captain-report-enums-boiler-colors',
  'captain-report-enums-instructed-speed-consumption-by',
  'captain-report-enums-instructed-speed-consumption-target',
  'captain-report-enums-seabed-types',
  'captain-reports-templates',
  'captain-report-drifting-reason',
  'captain-report-enums-delivered-at',
  'captain-report-enums-holds-on-delivery',
  'captain-report-enums-instructed-speed-allowance-unit',
  'captain-report-enums-instructed-consumption-allowance-unit',
  'captain-report-enums-phase-shift',
  'captain-report-enums-co-base-number',
  'captain-report-types',
  'creatable-captain-report-types',

  'manuals',

  'drills',

  'purchasing-units',
  'purchasing-requisition-statuses',
  'purchasing-table-settings',
  'purchasing-requisition-priorities',
  'purchasing-budgets',
  'email-templates',

  'mga-account-types',
  'mga-accounts',
  'mga-accounts-with-children',
  'mga-depletion-method',
  'mga-items-available',
  'mga-action-types',
  'mga-period-statuses',
  'stores',

  'account-types',
  'accounts',
  'budget-types',
  'budget-period-types',
  'budget-periods',
  'budgeting-report-field-types',
  'accounting_companies',
  'companies',
  'manning-agents',

  'wages-types',

  'chat-entity-types',

  'reports-performance-setups',
  'reports-performance',
  'reports-it-integration-logs',
  'budgeting-reports',
  'event-medical-expenses',

  'forex-rates',
  'phone-types',
  'email-types',
  'existing-class-numbers',
  'for-vessel-company',
  'case-tags',
  'spare-parts-quality',

  'payment-terms',
  'currencies',
  'permission-restriction-levels'
];

export default lists;
