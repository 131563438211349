import React, { useCallback, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
  toolbarConfig,
  pluginConfig,
  imagesConfig,
  tablesConfig,
  fontColor,
  fontBackgroundColor
} from './config';

import PropTypes from 'prop-types';

import useAutoSaveInputValue from '@/ts-common/utils/hooks/useAutoSaveInputValue';
import CustomImageUploadAdapterPlugin from './CustomImageUploadAdapterPlugin';

const CkEditor = ({
  onChange,
  onAutoSave = () => new Promise(resolve => resolve(null)),
  id,
  className = '',
  isFocused,
  onFocus,
  value,
  maxHeight,
  minHeight,
  customToolbar,
  customPlugins,
  placeholder = '',
  isFullEditor = false,
  uploadedImagesGroup = null,
  styled = false,
  onImageUpload,
  shouldRemovePastedImages = true, // By default should be true, in rarely cases we should allow users to copy/paste images with external urls
  uploadImagesRequestPath = '/files', // override the request path to upload files
  invisible,
  disabled,
  error
}) => {
  const [editor, setEditor] = useState();
  const editorRef = React.useRef();

  const [isLoading, setIsLoading] = useState(true);

  const { onAutoSave: autoSaveValue } = useAutoSaveInputValue(onAutoSave);

  let toolbar = customToolbar || toolbarConfig;
  let plugins = customPlugins || pluginConfig;

  const imageUploadCallback = useCallback(
    image => {
      onImageUpload([image]);
    },
    [onImageUpload]
  );

  const configToUploadImages =
    uploadedImagesGroup || !shouldRemovePastedImages
      ? {
          uploadImagesRequestPath,
          uploadedImagesGroup,
          imageUploadCallback,
          extraPlugins: [CustomImageUploadAdapterPlugin],
          image: {
            toolbar: [
              'imageTextAlternative',
              'toggleImageCaption',
              'imageStyle:inline',
              'imageStyle:block',
              'imageStyle:side',
              'linkImage'
            ]
          },
          allowedContent: 'img[alt,fileId,!src]{width,height}'
        }
      : {};

  const config = {
    plugins:
      (uploadedImagesGroup || !shouldRemovePastedImages) && !customPlugins
        ? [...plugins, ...imagesConfig.plugins]
        : plugins,
    toolbar:
      uploadedImagesGroup && !customToolbar ? [...toolbar, ...imagesConfig.toolbar] : toolbar,
    placeholder: placeholder,
    fontColor,
    table: tablesConfig,
    fontBackgroundColor,
    link: {
      defaultProtocol: 'https://',
      decorators: {
        isExternal: {
          mode: 'automatic',
          callback: url => url.startsWith('https://'),
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer'
          }
        }
      }
    },
    ...configToUploadImages
  };

  useEffect(() => {
    if (!window.DecoupledEditor) {
      return;
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      setEditor(null);
    };
  }, []);

  return isLoading ? null : (
    <div
      className={`ckeditor_container ${className} ${styled ? 'styled-editor' : ''} ${
        invisible ? 'invisible' : ''
      } ${disabled ? 'pointer-events-none disabled' : ''}`}
      onFocus={() => onFocus(true)}
      ref={editorRef}
    >
      <div className={`${isFullEditor ? 'document-editor' : 'small-editor'}`}>
        <div className={`${isFullEditor ? 'document-editor__toolbar' : 'small-editor__toolbar'}`}>
          <div
            className={`ckeditor__editable-container ${
              isFullEditor
                ? 'document-editor__editable-container'
                : 'small-editor__editable-container'
            } ${
              !isFullEditor && !isFocused ? 'small-editor__editable-container--notEditMode' : ''
            } ${error ? 'has_error' : ''}`}
            style={{ maxHeight: maxHeight || '', minHeight: minHeight || '' }}
          >
            {!isLoading ? (
              <CKEditor
                disableWatchdog
                onReady={innerEditor => {
                  if (innerEditor) {
                    // Move the toolbar above the editor
                    innerEditor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(
                        innerEditor.ui.view.toolbar.element,
                        innerEditor.ui.getEditableElement()
                      );

                    // Handle focus changes
                    innerEditor.ui.focusTracker.on('change:isFocused', (_, __, isFocused) => {
                      onFocus(isFocused);
                    });

                    innerEditor.editing.view.document.on('clipboardInput', (evt, data) => {
                      const dataTransfer = data.dataTransfer;
                      const htmlContent = dataTransfer.getData('text/html');
                      const plainText = dataTransfer.getData('text/plain');
                      const urlRegex = /https?:\/\/[^\s<]+/g;

                      // If disabled or no content, let clipboard handle it
                      if (
                        disabled ||
                        (!htmlContent && !plainText) ||
                        (htmlContent && !shouldRemovePastedImages)
                      ) {
                        return;
                      }

                      let finalContent = htmlContent || plainText;

                      // Parse HTML if available
                      if (htmlContent) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(htmlContent, 'text/html');

                        // Remove images
                        doc.querySelectorAll('img').forEach(img => img.remove());

                        // Convert plain URLs to <a> links
                        const walker = document.createTreeWalker(
                          doc.body,
                          NodeFilter.SHOW_TEXT,
                          null
                        );

                        let node;
                        while ((node = walker.nextNode())) {
                          const parentTag = node.parentNode.tagName;

                          // Ignore if already inside an <a> tag
                          if (parentTag !== 'A' && urlRegex.test(node.textContent)) {
                            const newContent = node.textContent.replace(urlRegex, url => {
                              return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                            });

                            // Replace node with HTML fragment containing links
                            const wrapper = document.createElement('span');
                            wrapper.innerHTML = newContent;
                            while (wrapper.firstChild) {
                              node.parentNode.insertBefore(wrapper.firstChild, node);
                            }
                            node.parentNode.removeChild(node);
                          }
                        }

                        // Convert updated HTML back to string
                        finalContent = doc.body.innerHTML;
                      } else {
                        // If plain text, wrap URLs in <a>
                        finalContent = plainText.replace(urlRegex, url => {
                          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                        });
                      }

                      // Convert modified HTML to CKEditor format and insert
                      const viewFragment = innerEditor.data.processor.toView(finalContent);
                      const modelFragment = innerEditor.data.toModel(viewFragment);

                      innerEditor.model.insertContent(
                        modelFragment,
                        innerEditor.model.document.selection
                      );

                      evt.stop();
                    });

                    setEditor(innerEditor);
                  }
                }}
                onError={({ willEditorRestart, ...e }) => {
                  console.log('ckeditor onError');
                  console.log(willEditorRestart);
                  console.log(e);

                  if (willEditorRestart) {
                    setEditor(editor.ui.view.toolbar.element.remove());
                  }
                }}
                onChange={(_, editor) => {
                  if (onChange) {
                    onChange(editor.getData());
                    autoSaveValue(editor.getData());
                  }
                }}
                id={id}
                editor={window.DecoupledEditor}
                data={value}
                config={config}
                error={error}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CkEditor;

CkEditor.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  placeholder: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  isFullEditor: PropTypes.bool,
  styled: PropTypes.bool,
  customToolbar: PropTypes.array,
  uploadedImagesGroup: PropTypes.string, // s3 group to store the uploaded images
  onImageUpload: PropTypes.func,
  error: PropTypes.string
};
